<template>
  <main class="main-services">
    <div class="container main-services__container">
      <h3 class="main-services__title title-bg">
        Выберите услугу
      </h3>
      <div class="main-content-bg">
        <div id="table-container">
          <div id="table-container__two">
            <table class="services-list">
              <tbody>
              <tr
                v-for="row in services"
                :key="row.id"
                class="services-list__item item"
              >
                <td class="item__name">
                  {{ row.title }}
                </td>
                <td class="item__price">
                  {{ row.price_max }}р
                </td>
                <td
                  v-if="!count(row.id)"
                  class="item__button"
                  @click="plus(row.id, 1)"
                >
                  +
                </td>
                <td
                  v-else
                  class="item__button inactive"
                >
                  +
                </td>
                <td class="item__number">
                  {{ count(row.id) }}
                </td>
                <td
                  class="item__button"
                  @click="plus(row.id, -1)"
                >
                  -
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="main-services__buttons">
          <div :class="{'hide-button': unpaidAndOneGood}" @click="$router.push('/terminal/barcode')"
               class="button button--left">
            <div class="button__inner">
              <p class="button__inner-title button__inner-title--light">
                Товары
              </p>
            </div>
          </div>
          <div class="total">
            <h3 class="total__title">
              Итого:
            </h3>
            <p class="total__price">
              {{ totalPrice }} р
            </p>
          </div>
          <div
            :class="{'hide-button': !isSelected()}"
            class="button button--right"
            @click="next"
          >
            <div class="button__inner">
              <p class="button__inner-title button__inner-title--black">
                Далее
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer">
      <div class="footer__container"/>
    </footer>
  </main>
</template>

<script>
import common from "../../components/mixins/common";

export default {
  name: "ChooseServices",

  mixins: [common],

  data() {
    return {
      selected: {}
    }
  },

  computed: {
    totalPrice() {
      return Object.values(this.selected).reduce((sum, curr) => sum + curr.count * curr.price, 0);
    },

    services() {
      return this.$store.getters.services
    }
  },

  watch: {
    selected: {
      handler(selected) {
        this.$store.commit('SET_SELECTED_SERVICES', selected)
      },
      deep: true
    }
  },

  mounted() {
    this.$store.dispatch('getServices')

    if (Object.keys(this.$store.getters.selectedServices).length)
      this.selected = this.$store.getters.selectedServices
  },

  methods: {
    next() {
      this.$store.commit('SET_TOTAL_PRICE', this.totalPrice);
      if (Object.keys(this.$store.getters.record).length) {
        this.$router.push('/terminal/choose-pay-method');
      } else {
        this.$router.push('/terminal/choose-master');
      }
    },

    plus(id, plus) {
      const service = this.services.find(item => item.id === id)
      console.log(service)
      if (typeof this.selected[id] === 'undefined') {
        this.$set(this.selected, id, {
          title: service.title,
          price: service.price_max,
          count: 0,
          // seance_length: service.staff[0] ? service.staff[0].seance_length : 0,
          seance_length: 0,
          discount: 0
        });
      }
      this.selected[id].count += plus
      if (this.selected[id].count < 1)
        delete this.selected[id]
    },

    count(id) {
      return typeof this.selected[id] === 'undefined' ? 0 : this.selected[id].count;
    },

    isSelected() {
      return Boolean(Object.keys(this.selected).length)
    }
  }

}
</script>

<style>
.hide-button {
  display: none;
}

.services-list {
  max-height: 530px;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  padding-right: 10px !important;
  display: block !important;
  margin-left: -10px !important; /* Сдвигаем таблицу на 20px влево */
}

.services-list::-webkit-scrollbar {
  width: 20px; /* Ширина скроллбара */

}

.services-list::-webkit-scrollbar-track {
  background-color: rgba( 79, 216, 219, 0.1);
}

.services-list::-webkit-scrollbar-thumb {
  background-color: rgba( 79, 216, 219, 0.5);
  border-radius: 10px;
}


table::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

table::-webkit-scrollbar-thumb:hover {
  background-color: rgba( 79, 216, 219, 1); /* Цвет ползунка при наведении */
}

.inactive {
  color: #5a6181;
}
</style>
