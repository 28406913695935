<template>
  <main class="payment-main">
    <div class="container payment-main__container">
      <h3 class="payment-main__title title-bg">
        Оплата наличными
      </h3>
      <div class="main-content-bg">
        <div class="payment-main__content">
          <h3 class="payment-main__make-title">
            Необходимо внести: {{ totalPrice }} р
          </h3>
          <p class="payment-main__make-subtitle">
            Внесите деньги по одной купюре
          </p>
          <h2 class="payment-main__introduced">Внесено:</h2>
          <input disabled class="name-content__input input-value" :placeholder="paid + ' р'" type="text">
          <img style="" class="notification notification__icon" :src="require('../../assets/terminal/images/dest/icon_info.png')" alt="icon_info">
          <p style="font-size: 32px; font-weight: bold" class="notification__title">Внимание!</p>
          <p style="margin-bottom: -5px; font-size: 32px;" class="notification__title">{{text_cash}}</p>
        </div>
        <div v-if="errors" v-html="kkmError" class="payment-card__title" style="position: absolute; top: 620px; left: 230px;"></div>
        <div class="main-services__buttons">
          <div @click="$router.go(-1)" class="button button--left" >
            <div class="button__inner">
              <p class="button__inner-title button__inner-title--light">
                Назад
              </p>
            </div>
          </div>
          <div style="width: 90px"></div>
          <div v-if="!inProgress && !errors && paid >= totalPrice" @click="next" class="button button--right">
            <div class="button__inner">
              <p class="button__inner-title button__inner-title--black">
                Далее
              </p>
            </div>
          </div>
          <div v-if="inProgress" class="button button--right button--light-blue--right">
            <div class="button__inner">
              <p class="button__inner-title button__inner-title--black">
                Обработка...
              </p>
            </div>
          </div>
          <div v-if="errors" class="button button--right button--light-blue--right">
            <div class="button__inner">
              <p class="button__inner-title button__inner-title--black">
                Ошибка
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import {apiClient} from "../../services/api";
  import department from "../../components/mixins/department";
  import payment from "../../components/mixins/payment";
  import common from "../../components/mixins/common";

  export default {
    name: "PayByCash",

    mixins: [department, payment, common],

    data() {
      return {
        text_cash: this.$store.getters.loggedIn.department_data.text_cash ?? 'Терминал сдачу не выдает!',
        inProgress: false,
        errors: false,
        startPay: false,
        ccnetInterval : null,
        kkmError: '',
      }
    },

    computed: {
      totalPrice() {
        return this.fullPrice || 0;
      },

      selectedService() {
        return Object.values(this.$store.getters.selectedServices)[0] || {};
      },

      paid: {
        get() {
          //return 800;
          return this.$store.getters.cashPaid;
        },
        set(value) {
          this.$store.commit('SET_CASH_PAID', value);
        }
      },

      socket() {
        return this.$store.getters.ccnetSocket;
      }
    },

    methods: {
      error(errors, kkmError) {
        console.log('Errors:', errors)
        if (kkmError) {
          this.inProgress = false
          this.errors = true
          this.kkmError = this.kkmErrorText(errors[1])
        }
      },

      next() {
        this.inProgress = true
        this.registerCheckFiscal(0, 0, false)
      },

      executeSuccess(e) {
        if (!e.Status) {
          apiClient.post('/yc-transaction/terminal/new-bindgood', {
            account: 'Основная касса',
            person_id: this.$store.getters.clientByPhone.person_id,
            amount: this.totalPrice,
            client_id: this.$store.getters.clientByPhone.new_yc_id || null,
            record_id: this.$store.getters.record.id || 0,
            visit_id: this.$store.getters.record.visit_id || 0,
            services: this.$store.getters.selectedServices,
            staff_id: this.$store.getters.selectedMaster.id || 0,
            goods: this.goodsToTransaction(),
            qrcode: e.QRCode || null
          }).then(res => {
            if (res.data[1].success) {
              // if (this.goodsArePresent) {
              //   apiClient.post('/yc-transaction/terminal/bindgood', this.goodsToTransaction()).then(res => {
              //     console.log('bindgood:', res)
              //     this.paid = 0;
              //     this.$router.push('/terminal/done')
              //   })
              // } else {
                console.log('Success:', res)
                this.paid = 0;
                // this.$router.push('/terminal/done')
              let receipt = {
                goods: this.goodsToTransaction(),
                total: this.totalPrice,
                services: this.$store.getters.selectedServices,
                qrCode: e.QRCode ?? '',
                numReceipt: e.CheckNumber ?? ''
              }
              localStorage.setItem("infoReceipt", JSON.stringify(receipt));
              this.$router.push('/terminal/print-receipt')
              // }
            } else {
              this.error(res, false)
            }
          }).catch(res => {
            this.error(res, false)
          })
        } else {
          this.error([e.Status, e.Error], true)
        }
      }
    },

    mounted() {
      if (this.paid < this.totalPrice)
        this.startPay = true;

      let vm = this;

      if (!this.$store.getters.ccnetSocket || this.$store.getters.ccnetSocket.readyState !== 1) {
        this.$store.commit('SET_CCNET_SOCKET', new WebSocket("ws://127.0.0.1:5000/"));
        this.$store.getters.ccnetSocket.onopen = function() {
          vm.$store.getters.ccnetSocket.send(JSON.stringify({
            method: 'connect',
            data: {
              port: vm.cashPort
            }
          }));
        }
      }

      this.socket.onmessage = function (event) {
        let socketData = JSON.parse(event.data);
        console.log(socketData)

        if (socketData.method === 'escrow') {
          vm.socket.send(JSON.stringify({
            'method': 'stack',
            'data': {}
          }));
        } else if(socketData.method === 'stacked'){
          vm.paid += socketData.bill.amount;

          if (vm.paid >= vm.totalPrice) {
            console.log('closing cashcode');
            vm.startPay = false;
            vm.socket.send(JSON.stringify({
              'method': 'close',
              'data': {}
            }));
          }
        } else if (socketData.method === 'returned') {
          vm.paid -= socketData.bill.amount;
        }
      }

      this.ccnetInterval = setInterval(() => {
        if (!this.startPay || this.socket.readyState !== 1)
          return;
        this.socket.send(JSON.stringify({
          'method': 'begin',
          'data': {}
        }));
      }, 3000)
    },

    beforeDestroy() {
      clearInterval(this.ccnetInterval);
      this.socket.send(JSON.stringify({
        'method': 'close',
        'data': {}
      }));
    }
  }
</script>

<style scoped>

</style>
