<template>
  <div
    class="toCallModal-backdrop"
    @click.self="close"
  >
    <div class="toCallModal">
      <form
        class="toCallModal-form"
        @submit.prevent.stop.exact="getFormData"
      >
        <div class="toCallModal-left">
          <div class="toCallModal-header">
            <h1 class="toCallModal-header__item toCallModal-header__name">
              {{ currentClient.firstname }}
            </h1>
            <span class="toCallModal-header__item toCallModal-header__phone">{{ currentClient.phone }}</span>
          </div>

          <div class="about-staff">
            <div class="toCallModal-body">
              <div class="toCallModal-body__item">
                Дата визита: <span class="toCallModal-body__value">{{ currentClient.date }}</span>
              </div>
              <div class="toCallModal-body__item">
                Мастер:
                <span class="toCallModal-body__value">{{ currentClient.master }}</span>
              </div>
              <div class="toCallModal-body__item">
                Точка визита: <span class="toCallModal-body__value">{{ currentClient.department }}</span>
              </div>
              <div class="toCallModal-body__item">
                Последний звонок: <span class="toCallModal-body__value" />
              </div>
            </div>
            <div class="about-staff__column edit-ivideon__column toCallModal-input">
              <div class="about-staff__left">
                <div class="toCallModal-input__item">
                  <app-input
                    v-model="currentClient.activity"
                    label="Чем занимаетесь"
                    placeholder="Род деятельности"
                    class-label="edit-staff__item focus-input toCallModal-input__label"
                    class-input="create-staff__value create-staff__value"
                  />
                </div>
                <div class="toCallModal-input__item">
                  <span class="toCallModal-input__title toCallModal-input__label">
                    Приложение
                  </span>
                  <multiselect
                    ref="select"
                    v-model="valueApplication"
                    class="select"
                    :options="answerApplication"
                    :value="answerApplication"
                    :searchable="false"
                    placeholder="Установлено приложение?"
                    :allow-empty="false"
                  />
                </div>
              </div>

              <div class="about-staff__right">
                <div class="toCallModal-input__item">
                  <app-input
                    v-model="rating"
                    type="number"
                    label="Оценка"
                    placeholder="От 1-10"
                    class-label="edit-staff__item focus-input toCallModal-input__label"
                    :class="{error:$v.rating.$error}"
                    class-input="create-staff__value create-staff__value"
                  />
                </div>
                <div class="toCallModal-input__item">
                  <span class="toCallModal-input__title toCallModal-input__label">
                    Как часто стрижетесь?
                  </span>
                  <multiselect
                    ref="select"
                    v-model="period_haircut_value"
                    label="title"
                    track-by="title"
                    class="select"
                    :options="period_haircut_answer"
                    :searchable="false"
                    :allow-empty="false"
                    placeholder="Чтобы нам напомнить вовремя"
                  />
                </div>
              </div>
            </div>
          </div>
          <app-button
            style="margin-bottom: 20px"
            type="submit"
          >
            Все данные добавлены
          </app-button>
          <div>
            <app-button
              class="button--pink"
              @click.stop.prevent.self="noAnswer"
            >
              Недозвон +24ч
            </app-button>
            <div
              style="display: inline-flex;position: relative;"
            >
              <app-button
                style="margin-left: 11px"
                class="call-back-title button--pink"
                @click.stop.prevent.self="callBack"
              >
                Перезвонить
              </app-button>
              <app-button-add
                class="call-back-icon"
                @click.stop.prevent.self="callBackModal=!callBackModal"
              />
              <app-input
                v-if="callBackModal"
                id="datetime"
                v-model="time_ring"
                class="call-back-modal"
                type="datetime-local"
              />
            </div>
          </div>
        </div>
        <div class="toCallModal-right">
          <div
            class="toCallModal__comments-history comments"
          >
            <app-comments />
            <div class="comments-bottom">
              <app-input
                class-input="comments__input"
                placeholder="Написать комментарий к задаче"
              />
              <button class="comments__send" />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AppButton from "../common/Button";
import AppInput from "../common/Input";
import AppButtonAdd from "../common/ButtonAdd";
import {helpers, required} from "vuelidate/lib/validators";
import AppComments from "./Comments";


const numbers = helpers.regex('numbers', /^([1-9]|10)$/i);
export default {
  name: "ClientModal",
  components: {
    AppComments,
    AppButton,
    AppButtonAdd,
    AppInput
  },
  data() {
    return {
      rating: '',
      time_ring: null,
      callBackModal: null,
      period_haircut_day: null,
      period_haircut_answer: [
        {
          title: 'раз в месяц - 27 дней',
          day: 27
        },
        {
          title: 'раз в полтара месяца - 41день',
          day: 41
        },
        {
          title: 'раз в два месяца - 56 дней',
          day: 56
        }
      ],
      answerApplication: ['да', 'нет'],
      period_haircut_value: '',
      valueApplication: '',
    };
  },
  validations: {
    rating: {
      numbers,
      required
    }
  },
  computed: {
    period_haircut() {
      if (this.currentClient.period_haircut) {
        return this.currentClient.period_haircut
      } else {
        return this.answer
      }
    },
    currentClient() {
      if (this.$store.state.callCenter.currentEditingId) {
        return this.$store.state.callCenter.currentEditingId
      }
      return ''
    }
  },
  methods: {
    close() {
      this.$emit('close');
      this.$store.commit('CLEAR_EDITING_ID')
      this.cleanFields()
    },
    async onSubmit(data) {
      const res = await this.$store.dispatch("updateUsersOneRecordByDate", data)
      this.cleanFields()
      if (res) await this.close()
    },
    getFormData() {
      const formData = {
        date: this.currentClient.date,
        firstname: this.currentClient.firstname,
        activity: this.currentClient.activity,
        attendance: this.currentClient.attendance,
        master: this.currentClient.master,
        software: this.valueApplication,
        record_id: this.currentClient.record_id,
        rating: this.rating,
        period_haircut: this.period_haircut_value,
      }
      this.checkValid(formData)
    },
    checkValid(data) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.onSubmit(data)
      }
    },
    callBack() {
      if (this.time_ring) {
        const formData = {
          time_ring: this.time_ring.split('T').join(' ') + ':00',
          record_id: this.currentClient.record_id
        }
        this.onSubmit(formData)
      } else {
        return null
      }
    },
    noAnswer() {
      const formData = {
        record_id: this.currentClient.record_id,
        time_ring: this.formatDate() + ' 12:00:00'
      }
      this.onSubmit(formData)
    },
    cleanFields() {
      this.rating = ''
    },
    formatDate() {
      let d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + (d.getDate() + 1),
        year = d.getFullYear();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      return [year, month, day].join('-');
    },
  },
}
</script>

<style scoped>

</style>
