import axios from 'axios'
import Echo from "laravel-echo";
import cookie from "js-cookie";
window.Pusher = require('pusher-js');

let server = window.location.hostname === 'web.20x80.one' ? 'https://backend.20x80.one' : 'https://bis.zdato.ru';
const port = window.location.hostname === 'web.20x80.one' ? 6001 : 3030;
if (!!process.env.VUE_APP_LOCAL_BACKEND && process.env.NODE_ENV === 'development')
  server = 'http://bis-back.zdato.ru'; //local dev server
  server = 'https://backend.20x80.one'
export const apiClient = axios.create({
  baseURL: server + `/api`,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const echo = new Echo({
  authEndpoint: server + '/api/broadcasting/auth',
  auth: {
    headers: {
      Authorization: 'Bearer ' + cookie.get('jwt')
    }
  },
  broadcaster: 'pusher',
  key: 'qwerty',
  wsHost: server.split('://')[1],
  wsPort: port,
  wssPort: port,
  forceTLS: server.split('://')[0] === 'https',
  disableStats: true,
});

export const wsEcho = () => {
  return new Echo({
    authEndpoint: server + '/api/broadcasting/auth',
    auth: {
      headers: {
        Authorization: 'Bearer ' + cookie.get('jwt')
      }
    },
    broadcaster: 'pusher',
    key: 'qwerty',
    wsHost: server.split('://')[1],
    wsPort: port,
    wssPort: port,
    forceTLS: server.split('://')[0] === 'https',
    disableStats: true,
  });
}
