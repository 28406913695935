import {apiClient, echo} from "../../services/api";
import cookie from 'js-cookie'

export default {

  state: {
    user: null
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData
      cookie.set('user', userData)
      cookie.set('jwt', userData.token)
      apiClient.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`
      echo.connector.pusher.config.auth.headers['Authorization'] = `Bearer ${userData.token}`
    },
    CLEAR_USER_DATA() {
      cookie.remove('user')
      cookie.remove('jwt')
      apiClient.defaults.headers.common['Authorization'] = null;

    }
  },
  actions: {
    async login({commit, dispatch}, credentials) {
      const {data} = await apiClient.post('/login', credentials)
      commit('SET_USER_DATA', data)
      return data
    },
    async logout({commit}) {
      /*     const res = await apiClient.post('/logout')
           console.log(res)*/
      commit('CLEAR_USER_DATA')
    }
  },
  getters: {
    loggedIn(state) {
      if (state.user) {
        console.log(state.user)
        return state.user.user_data
      }
      return null
    }
  }
}
