<template>
  <form
    class="create-staff"
    action=""
    @submit.prevent="onSubmit"
  >
    <div class="about-staff">
      <div class="about-staff__column">
        <div class="about-staff__left">
          <app-input

            label="Фамилия"
            class-label="edit-staff__item focus-input"
            class-input="create-staff__value"
          />
          <app-input

            label="Имя"
            class-label="edit-staff__item focus-input"
            class-input="create-staff__value"
          />
          <app-input

            label="Отчество"
            class-label="edit-staff__item focus-input"
            class-input="create-staff__value"
          />
          <label
            for="phone"
            class="edit-staff__item focus-input"
          >
            Телефон
            <the-mask
              id="phone"
              class="create-staff__value"
              mask="+7 ### ### ## ##"
            />
          </label>
        </div>
      </div>
    </div>
    <app-button
      type="submit"
      class="button"
    >
      <slot>Сохранить</slot>
    </app-button>
  </form>
</template>

<script>
import AppInput from "../common/Input";
import AppButton from "../common/Button";
import {TheMask} from "vue-the-mask";

export default {
  name: "EditYClients",
  components: {
    AppInput,
    AppButton,
    TheMask,
  },
  props: {
    person: {
      type: Object,
      default: Object
    }
  },
}
</script>

<style scoped>

</style>
