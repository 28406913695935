import {apiClient} from "../../services/api";

export default {
  state: {
    currentEditingId: null,
    showModal: false,
    afterFirstVisit: {
      clients: []
    }
  },
  mutations: {
    SET_CLIENTS_FIRST_VISIT(state, clients) {
      this.state.afterFirstVisit.clients = clients
    },
    SET_USERS(state, clients) {
      state.afterFirstVisit.clients = clients
      /*    const existingClient = state.afterFirstVisit.clients.find(client => client === eventClient)
          if (existingClient) existingClient.comment = eventClient.comment*/
    },
    CHANGE_SHOW_MODAL(state, value) {
      state.showModal = value
    },
    SET_EDITING_ID(state, id) {
      state.currentEditingId = id;
    },
    CLEAR_EDITING_ID(state) {
      state.currentEditingId = null;
    },
  },
  actions: {
    async getUsersOneRecordByPages({commit}, currentPage = '/users-one-record?page=1') {
      try {
        const {data: {data}} = await apiClient.post(currentPage)
        commit('SET_USERS', data)
      } catch (e) {

      }
    },
    async getUsersOneRecordByDate({commit}, date) {
      try {
        const {data: {data}} = await apiClient.post('/users-one-record', date)
        commit('SET_USERS', data)
      } catch (e) {

      }
    },
    async updateUsersOneRecordByDate({commit, dispatch}, date) {
      const {record_id} = date
      try {
        const res = await apiClient.post(`/users-one-record/${record_id}`, date)
        if (res) dispatch('getUsersOneRecordByDate')
        return res
      } catch (e) {

      }
    },
  },
  getters: {}
}
