<template>
  <main class="pay-method">
    <div class="container pay-method__container">
      <h3 class="pay-method__title title-bg">
        Выберите способ оплаты
      </h3>
      <div class="pay-method__content main-content-bg">
        <div class="pay-method__block">
          <div class="pay-method__master">
            <div class="frame pay-method__frame">
              <img
                class="master-photo pay-method__photo"
                :src="master.avatar"
                alt="master-photo"
              >
            </div>
          </div>
          <div class="pay-method__info info-pay">
            <h3 class="info-pay__date">
              {{ master.date }} | {{ master.time }}
            </h3>
            <p class="info-pay__service">
              Мастер: {{ master.name ? master.name.split(' ')[0] : '' }}
            </p>
            <p class="info-pay__service">
              {{ selectedService ? selectedService.title : '' }}
            </p>
          </div>
        </div>
        <div class="pay-method__icons">
<!--          <span style="color: #4fd8db; margin-top: 170px; font-size: 24px; margin-right: 80px;">Оплата наличными<br>временно недоступна</span>-->
          <img
            :src="require('../../assets/terminal/images/dest/pay-method-nal.svg')"
            class="pay-method__cash"
            @click="payByCash"
          >
          <img
            :src="require('../../assets/terminal/images/dest/pay-method-card.svg')"
            class="pay-method__card"
            @click="payByCard"
          >
        </div>
        <div class="main-services__buttons pay-method__buttons">
          <div :class="{'hide-button': unpaidAndOneGood}" @click="$router.push('/terminal/barcode')" class="button button--left">
            <div class="button__inner">
              <p class="button__inner-title button__inner-title--light">
                Добавить товары
              </p>
            </div>
          </div>
          <div class="total">
            <h3 class="total__title">
              Итого:
            </h3>
            <p class="total__price">
              {{ totalPrice }} р
            </p>
          </div>
          <div @click="$router.push('/terminal/choose-services')" class="button button--right ">
            <div class="button__inner">
              <p class="button__inner-title button__inner-title--black">
                Добавить услуги
              </p>
            </div>
          </div>
        </div>
      </div>
      <footer class="footer">
        <div class="footer__container" />
      </footer>
    </div>
  </main>
</template>

<script>
  import common from "../../components/mixins/common";
  import payment from "../../components/mixins/payment";

  export default {
    name: "ChoosePayMethod",
    mixins: [common, payment],

    data() {
      return {

      }
    },

    computed: {
      master() {
        return this.$store.getters.selectedMaster;
      },
      totalPrice() {
        return this.fullPrice || 0;
      },
      selectedService() {
        return Object.values(this.$store.getters.selectedServices)[0];
      },
    },

    mounted() {
      console.log(this.$store.getters.selectedServices)
    },

    methods: {
      payByCash() {
        this.$router.push('/terminal/pay-by-cash');
      },
      payByCard() {
        this.$router.push('/terminal/pay-by-card');
      },
    },

  }
</script>

<style scoped>

</style>
