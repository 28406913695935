import {apiClient} from '../../services/api'

export default {
  state: {
    persons: [],
    person: null,
    personsIdIvideon: [],
    personIdIvideon: null,
    personsYClients: [],
  },
  mutations: {
    SET_PERSONS(state, persons) {
      state.persons = persons
    },
    SET_PERSON(state, person) {
      state.person = person
    },
    SET_PERSONS_ID_IVIDEON(state, personsIvideon) {
      state.personsIdIvideon = personsIvideon
    },
    SET_PERSONS_YCLIENTS(state, personsYClients) {
      state.personsYClients = personsYClients
    },
    SET_PERSON_ID_IVIDEON(state, personIvideon) {
      state.personIdIvideon = personIvideon
    }
  },
  actions: {
    async getAllPersons({commit, dispatch}) {
      try {
        const {data: {data}} = await apiClient.get('/persons')
        console.log(data, 'persons')
        commit('SET_PERSONS', data)
      } catch (error) {
        const notification = {
          type: 'error',
          message: `FetchPersons have error: ` + error.message
        }
        dispatch('notification/add', notification, {root: true})
      }
    },
    async getAllPersonsIdIvideon({commit, dispatch}) {
      try {
        const {data} = await apiClient.get('/load-id-ivideon')
        commit('SET_PERSONS_ID_IVIDEON', data)
      } catch (error) {
        const notification = {
          type: 'error',
          message: `Load-id-ivideon have error: ` + error.message
        }
        dispatch('notification/add', notification, {root: true})
      }
    },
    async getPersonIvideonById({commit, dispatch}, id) {
      try {
        const {data} = await apiClient.get(`/load-id-ivideon/${id}`)
        commit('SET_PERSON_ID_IVIDEON', data)
      } catch (error) {
        const notification = {
          type: 'error',
          message: `IvideonById have error: ` + error.message
        }
        dispatch('notification/add', notification, {root: true})
      }
    },
    async getPersonById({commit, dispatch}, id) {
      try {
        const {data: {data}} = await apiClient.get(`/persons/edit/${id}`)
        commit('SET_PERSON', data)
      } catch (error) {
        const notification = {
          type: 'error',
          message: `getPersonById have error: ` + error.message
        }
        dispatch('notification/add', notification, {root: true})
      }
    },
    async updatePerson({commit, dispatch}, person) {
      const {person_id} = person
      try {
        const {data: {success}} = await apiClient.post(`/persons/update/${person_id}`, person)
        return success
      } catch (error) {
        const notification = {
          type: 'error',
          message: `CreatePerson have error: ` + error.message
        }
        dispatch('notification/add', notification, {root: true})
      }
    },
    async createPersonIvideon({commit, dispatch}, person) {
      const {person_ivideon_id} = person
      try {
        return await apiClient.post(`/create-person/${person_ivideon_id}`, person)
      } catch (error) {
        const notification = {
          type: 'error',
          message: `CreatePerson have error: ` + error.message
        }
        dispatch('notification/add', notification, {root: true})
      }
    },
    async getPersonsByYClients({commit, dispatch}, ycId) {
      try {
        const {data: {staffYC}} = await apiClient.get(`/list-staff-yc?yc_company_id=${ycId}`)
        console.log(staffYC, 'staffYC')
        commit('SET_PERSONS_YCLIENTS', staffYC)
      } catch (e) {

      }
    },
    async createPerson({commit, dispatch}, formData) {
      try {
        return await apiClient.post('/create-personal', formData)
      } catch (e) {

      }
    }
  },
  getters: {
    person(state) {
      return this.state.person
    }
  }
}

