<template>
  <main class="main">
    <div class="container main__container">
      <h2 class="main__title">Георгий вас не узнает.</h2>
      <h3 class="main__subtitle">Введите ваш номер телефона.</h3>

      <div class="buttons buttons__container">
        <div @click="next" class="button button--right button--light-blue--right">
          <div class="button__inner">
            <p class="button__inner-title button__inner-title--black">
              Далее
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  export default {
    name: "Unrecognized",

    data() {
      return {

      }
    },

    methods: {
      next() {
        this.$router.push('/terminal/phone');
      },
    },

    mounted() {

    },


  }
</script>

<style scoped>

</style>
