<template>
  <main class="main-services">
    <div class="container main-services__container">
      <h3 class="main-services__title title-bg">
        Меню
      </h3>
      <div class="main-content-bg">
        <div id="table-container">
          <div id="table-container__two">
            <table style="width: 490px; overflow: hidden">
              <tr>
                <td>
                  <div class="pay-btn" @click="$router.push('/terminal/choose-services')" href="javascript:void(0)">
                    Новая запись
                  </div>
                </td>
                <td>
                  <div v-if="!searching" class="pay-btn" @click="$router.push('/terminal/barcode')" href="javascript:void(0)">Купить косметику</div>
                  <div v-else class="searching">Поиск...</div>
                </td>
              </tr>
            </table>
            <table class="services-list">
              <tbody>
              <tr
                v-for="row in $store.getters.unpaidRecords"
                :key="row.id"
                class="services-list__item item"
              >
                <td class="item__name" style="font-size: 16px;">
                  {{ sumServices(row).date }} (Мастер - {{ row.staff ? row.staff.name : '' }})
                </td>
                <td class="item__price">
                  {{ sumServices(row).price }} р
                </td>
                <td class="item__price" style="width: 300px;">
                  <div class="pay-btn" @click="toPay(row)" href="javascript:void(0)">Оплатить&nbsp;визит</div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="main-services__buttons">
          <div @click="$router.go(-2)" class="button button--left">
            <div class="button__inner">
              <p class="button__inner-title button__inner-title--light">
                Назад
              </p>
            </div>
          </div>
          <div class="total">
            <h3 class="total__title">

            </h3>
            <p class="total__price">

            </p>
          </div>
          <div
            class="button button--right button--light-blue--right hide-button"
            @click="$router.push('/terminal/choose-services')"
          >
            <div class="button__inner">
              <p class="button__inner-title button__inner-title--black">
                Пропустить
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {apiClient} from '@/services/api.js';

export default {
  name: "MenuTmp",

  components: {},

  data() {
    return {
      searching: false,
    }
  },

  beforeMount() {
    this.refresh();
  },

  mounted() {
    console.log('unpaid', this.$store.getters.unpaidRecords);
  },

  methods: {
    sumServices(record) {
      let services = record.services
      let total = {date: '', price: 0, selectedServices: {}}
      for (let idx in services) {
        total.price += services[idx].cost
        total.selectedServices[services[idx].id] = {
          id: services[idx].id,
          title: services[idx].title,
          price: services[idx].cost,
          cost_per_unit: services[idx].cost_per_unit,
          first_cost: services[idx].first_cost,
          count: services[idx].amount,
          discount: services[idx].discount
        }
      }
      total.date = record.date
      return total
    },

    toPay(record) {
      const recData = this.sumServices(record)
      this.$store.commit('SET_TOTAL_PRICE', recData.price)
      let master = record.staff
      master.date = recData.date.split(' ')[0]
      master.time = recData.date.split(' ')[1]
      //console.log(recData.selectedServices)
      //return
      this.$store.commit('SET_SELECTED_MASTER', master)
      if (record.record_id)
        record.id = record.record_id
      this.$store.commit('SET_RECORD', record)
      this.$store.commit('SET_SELECTED_SERVICES', recData.selectedServices)
      this.$store.getters.clientByPhone.new_yc_id = record.client.id
      // this.$router.push('/terminal/choose-pay-method')
      this.$router.push('/terminal/send-receipt')
    },

    refresh() {
      //this.searching = true;
      if (!this.$store.getters.clientByPhone.phone)
        return;
      apiClient.get('/refresh-records?phone=' + encodeURIComponent(this.$store.getters.clientByPhone.phone)).then(res => {
        console.log(res.data.data);
        this.$store.commit('SET_UNPAID_RECORDS', res.data.data);
        this.searching = false;
      });
    }
  }
}
</script>

<style>
.pay-btn {
  cursor: pointer;
  background-color: #4fd8db;
  color: black;
  padding: 3px;
  font-size: 18px;
  font-weight: bold;
  width: 327px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searching {
  color: cyan;
  font-size: 18px;
}
</style>
