<template>
  <button
    v-bind="$attrs"
    :class="{button:true}"
    v-on="$listeners"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {}
}
</script>

<style lang="scss">

</style>
