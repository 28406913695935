<template>
  <label
    :class="classLabel"
    :for="id"
  >
    <slot />

    <textarea
      :id="id"
      :value="value"
      :placeholder="placeholder"
      :class="classInput"
      @input="$emit('input', $event.target.value)"
    />
  </label>
</template>

<script>
export default {
  name: "Textarea",
  props: {
    classLabel: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    classInput: {
      type: [Array, Object, String],
      default: function () {
        return '';
      },
    },
    placeholder: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
