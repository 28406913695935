import Vue from "vue";
import phoneSVG from '../../assets/images/CallCenter/phone.svg'
import AppTextarea from '../common/Textarea'

export default Vue.extend({
  components: {
    'app-textarea': AppTextarea
  },

  data() {
    return {
      answer: ['раз в месяц', 'раз в полтара месяца', 'раз в два месяца'],
      value: '',
      firstname: null,
      client: null,
    };
  },
  computed: {
    period_haircut() {
      if (this.params.data.period_haircut) {
        return this.params.data.period_haircut
      } else {
        return this.answer
      }
    }
  },
  beforeMount() {

  },
  mounted() {

  },
  created() {
    this.firstname = this.params.data.firstname
    this.client = this.params.data
  },
  methods: {

    getValue() {
      return this.firstname
    },
  },


  template: `
    <div class="toCallModal-cell">
    <div>
      <button class="edit-person"><img src="${phoneSVG}" alt="edit"></button>
    </div>
    <div>
      {{ firstname }}
    </div>
    <div class="toCallModal">
      <div class="toCallModal-header">
        <h1 class="toCallModal-header__item">{{ client.firstname }}</h1>
        <span class="toCallModal-header__item">{{ client.phone }}</span>
        <span class="toCallModal-header__item">{{ client.date }}</span>
      </div>
      <div class="toCallModal-body">
        <div class="toCallModal-body__item toCallModal-body-comment">
          <app-textarea
            name="comment"
            :id="'comment'"
            v-model="client.comment"
            class-input="edit-staff__comment"
            class-label="edit-staff__item color-label"
            placeholder="Введите текст комментария"
          >
            <slot>
              Комментарий
            </slot>
          </app-textarea>
        </div>
        <div class="toCallModal-body__item">
         <span class="toCallModal-body-haircut">
            Как часто стрижетесь
         </span>
          <multiselect
            ref="select"
            v-model="value"
            class="select"
            :options="period_haircut"
            :value="answer"
            :searchable="false"
            :allow-empty="false"
          />
        </div>
        <div class="toCallModal-body__item">
          {{ client.age }}
        </div>
      </div>
    </div>
    </div>
  `

});
