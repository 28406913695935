<template>
  <form
    class="report-form"
    action=""
  >
    <div class="report-form__block-title block-title">
      <reportsSVG />
      <h3 class="block-title__name">
        Отчет
      </h3>
      <h3 class="block-title__subtitle">
        "Название отчета"
      </h3>
    </div>
    <app-select
      v-model="event.point"
      hidden-option="Выберите точку"
      class-label="report-form__point-label select__label"
      class-select="report-form__point select__value"
      class-option="select-point__title select__title"
      :options="departments"
      :params="'department_name'"
    />
    <app-input
      v-model="event.receiver"
      class-label="report-form__receiver receiver-title"
      class-input="receiver-title__value"
      type="text"
      label="Получатели"
      placeholder="Начините вводить имя..."
    />

    <app-select
      v-model="event.time"
      hidden-option="Выберете удобное время"
      label="Время отправки"
      class-label="select__label"
      class-select="select__value"
      class-option="select__title"
      :options="time"
    />
    <div class="report-form__time-options time-options">
      <div class="time-options__item">
        <app-select
          v-model="event.beforeOpening"
          hidden-option="Не выбрано"
          label="До открытия"
          class-label="time-options__title"
          class-select="time-options__time"
          :options="beforeOpening"
        />
      </div>
      <div class="time-options__item">
        <app-select
          v-model="event.beforeClosing"
          hidden-option="Не выбрано"
          label="До закрытия"
          class-label="time-options__title"
          class-select="time-options__time"
          :options="beforeClosing"
        />
      </div>
    </div>
    <h3 class="report-form__method-sending">
      Способ отправки
    </h3>

    <app-checkbox v-model="event.methodSending.email">
      e-mail
    </app-checkbox>
    <app-checkbox v-model="event.methodSending.telegram">
      telegram
    </app-checkbox>
  </form>
</template>

<script>
import reportsSVG from '../assets/images/repostsSVG.svg?inline'
import AppInput from './common/Input'
import AppSelect from './common/Select'
import AppCheckbox from './common/Checkbox'

export default {
  name: 'ReportsClientsForm',
  components: {
    AppSelect,
    AppInput,
    reportsSVG,
    AppCheckbox
  },
  data() {
    return {
      points: ['Выбор 1', 'Выбор 2'],
      time: ['время 1', 'время 2'],
      beforeOpening: ['время 1', 'время 2'],
      beforeClosing: ['время 1', 'время 2'],
      event: {
        receiver: '',
        point: '',
        time: '',
        beforeOpening: '',
        beforeClosing: '',
        checked: [],
        methodSending: {
          email: false,
          telegram: false
        }
      }
    }
  },
  computed: {
    departments() {
      return this.$store.state.departments.departments
    }
  },
  watch: {
    departments(departments) {
      this.rowData = departments
    }
  },
  created() {
    this.$store.dispatch('fetchDepartments')
  },
}
</script>

<style lang="scss">

</style>
