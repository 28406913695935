<template>
  <form
    class="create-staff"
    action=""
  >
    <div class="about-staff">
      <div class="about-staff__column">
        <div class="about-staff__left">
          <app-input
            v-model.trim="localPerson.lastname"
            label="Фамилия"
            class-label="edit-staff__item focus-input"
            class-input="create-staff__value"
          />
          <app-input
            v-model.trim="localPerson.firstname"
            label="Имя"
            class-label="edit-staff__item focus-input"
            class-input="create-staff__value"
          />
          <app-input
            v-model.trim="localPerson.fatherland"
            label="Отчество"
            class-label="edit-staff__item focus-input"
            class-input="create-staff__value"
          />
        </div>
        <div class="about-staff__right">
          <label
            for="phone"
            class="edit-staff__item focus-input"
          >
            Телефон
            <the-mask
              id="phone"
              v-model="localPerson.phone"
              class="create-staff__value"
              mask="+7 ### ### ## ##"
            />
          </label>
          <app-input
            v-model="localPerson.email"
            label="Email"
            type="email"
            class-label="edit-staff__item focus-input"
            class-input="create-staff__value create-staff__value--email"
          />
        </div>
      </div>
      <app-textarea
        :id="'comment'"
        v-model="localPerson.comment"
        class-input="edit-staff__comment"
        class-label="edit-staff__item focus-input"
        placeholder="Введите текст комментария"
      >
        <slot>
          Комментарий
        </slot>
      </app-textarea>
    </div>
    <div class="staff-buttons">
      <app-button
        v-if="!newStaff"
        class="button button--disabled"
        @click.prevent.stop.exact.native="$emit('removePerson',localPerson)"
      >
        <slot>Удалить</slot>
      </app-button>
      <slot name="save" />
    </div>
  </form>
</template>

<script>
import AppInput from "../common/Input";
import AppButton from "../common/Button";
import {TheMask} from "vue-the-mask";
import AppTextarea from "../common/Textarea";

export default {
  name: "EditGeneral",
  components: {
    AppInput,
    AppButton,
    TheMask,
    AppTextarea,
  },
  props: {
    person: {
      type: Object,
      default: Object
    },
    newStaff: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      event: {
        person: ''
      }
    }
  },
  computed: {
    localPerson() {
      if (!this.newStaff) {
        return this.person
      } else {
        return this.event.person
      }
    }
  },
  watch: {
    'localPerson': {
      handler: function (newVal) {
        this.$emit('submit', newVal)
      },
      deep: true
    },
  },
  created() {
    this.event.person = this.person
  },
}
</script>

<style scoped>

</style>
