<template>
  <main class="payment-card-main payment-card">
    <div class="container payment-card__container">
      <h1 class="payment-card__title"></h1>
      <!-- <h3 class="payment-card__subtitle">Поиск неоплаченных записей...</h3> -->
    </div>
  </main>
</template>

<script>
  import {apiClient} from "../../services/api";

  export default {
    name: "SearchUnpaidRecords",

    beforeMount() {
      // apiClient.get('/unpaid-records?person_id=' + this.$store.getters.clientByPhone.person_id + '&client_id=' + this.$store.getters.clientByPhone.new_yc_id)
      /*apiClient.get('/unpaid-records?user_id=' + this.$store.getters.clientByPhone.id)
      .then(res => {
        console.log(res.data.data);
        if (!res.data.data.length) {
          // this.$router.push('/terminal/choose-services')
        } else {
          // this.$store.commit('SET_UNPAID_RECORDS', res.data.data)
        }
      }).finally(res => {
        this.$router.push('/terminal/menu-tmp')
      });*/
      this.$router.push('/terminal/menu-tmp');
    }
  }
</script>

<style scoped>

</style>
