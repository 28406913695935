<template>
  <section
    class="edit-staff"
  >
    <div>
      <h2 class="header-main">Настройки салона</h2>
      <br>
      <h1 class="header-department">{{ department.department_name }}</h1>
      <br>
      <div>
        <span class="header-tab">Терминал</span>
        <br>
        <br>
        <fieldset>
          <legend>YClients</legend>
          <img v-if="!ycAccounts" :src="require('../assets/images/spinner-3.gif')">
          <Select
            v-if="ycAccounts"
            :options="ycAccounts"
            label="Касса для наличных расчётов"
            class-label="label-select"
            class-option="option-select"
            params="title"
            second-params="id"
            v-model="ycAccountCash"
          />
          <br>
          <Select
            v-if="ycAccounts"
            :options="ycAccounts"
            label="Касса для безналичных расчётов"
            class-label="label-select"
            class-option="option-select"
            params="title"
            second-params="id"
            v-model="ycAccountCard"
          />
        </fieldset>
        <fieldset>
          <legend>ivideon</legend>
          <img v-if="!cameras" :src="require('../assets/images/spinner-3.gif')">
          <Select
            v-if="cameras"
            :options="cameras"
            label="Камера терминала"
            class-label="label-select"
            class-option="option-select"
            params="camera_name"
            second-params="id"
            v-model="cameraId"
          />
        </fieldset>
        <fieldset>
          <legend>Купюроприёмник</legend>
          <label class="label-select">Порт</label>
          <input
            type="text"
            placeholder="например, COM3" 
            class="input-text"
            v-model="cashPort"
            maxlength="10"
          />
        </fieldset>
      </div>
    </div>
    <br>

    <button @click="$router.push('/schedule')" class="button button close">Закрыть</button>
    <div style="width: 20px; display: inline-block;"></div>
    <button @click="save" v-if="!saving" class="button button">Сохранить</button>
    <img v-else class="close-spinner" :src="require('../assets/images/spinner-3.gif')">
  </section>
</template>

<script>
  import {apiClient} from "../services/api";
  import Select from "../components/common/Select";

  export default {
    name: "DepartmentSettings",
    components: {Select},
    data() {
      return {
        department: {},
        ycAccounts: null,
        ycAccountCash: '',
        ycAccountCard: '',
        cameras: null,
        cameraId: '',
        saving: false
      }
    },

    mounted() {
      apiClient.get('/departments/' + this.$route.params.id)
        .then(res => {
          this.department = res.data.data
          this.ycAccountCard = this.department.terminal.yc_account_card.toString()
          this.ycAccountCash = this.department.terminal.yc_account_cash.toString()
          this.cameraId = this.department.terminal.camera_id + ''
          return apiClient.get('/department/accounts?yc_company_id=' + res.data.data.yc_company_id)
        })
        .then(res => {
          this.ycAccounts = res.data.data
        })

      apiClient.get('/cameras-by-department/' + this.$route.params.id)
        .then(res => {
          this.cameras = res.data.data
          console.log('cam', this.cameras)
        })
    },

    methods: {
      save() {
        this.saving = true
        apiClient.patch('/departments/' + this.$route.params.id, this.$data)
          .then(res => {
            this.$router.push('/schedule')
          })
      }
    },

    computed: {
      cashPort: {
        get(){
          if (!this.department.terminal)
            return ''
          return this.department.terminal.cash_port
        },
        set(value) {
          this.department.terminal.cash_port = value
        }
      }
    }
  }
</script>

<style scoped>
  .header-main {
    font-size: 22px;
  }
  .header-department {
    font-size: 24px;
  }
  .close {
    background-color: #8A97AE;
  }
  .close-spinner {
    position: absolute;
    margin-left: 40px;
    height: 40px;
  }
  .header-tab {
    font-size: 20px;
    background-color: #E6E9FF;
    padding: 5px;
  }
  fieldset {
    border: 1px solid #8A97AE;
  }
</style>

<style>
  .label-select {
    display: block;
    margin-bottom: 5px;
  }
  .option-select {
    font-size: 15px;
    color: black;
  }
  .input-text {
    border: 2px solid gray !important;
    border-radius: 3px;
  }
</style>
