import {apiClient} from '../../services/api'


export default {
  state: {
    clients: [],
    pages: []
  },
  mutations: {
    SET_CLIENTS(state, clients) {
      state.clients = clients
    },
    SET_PAGES(state, pages) {
      state.pages = pages
    }
  },
  actions: {
    async getClients({commit, dispatch}) {
      try {
        const {data: {data: {data}}} = await apiClient.get('/users')
        commit('SET_CLIENTS', data)
      } catch (error) {
        const notification = {
          type: 'error',
          message: `getClients have error: ` + error.message
        }
        dispatch('notification/add', notification, {root: true})
      }
    },
    async removeClient({commit, dispatch}, {person_id}) {
      try {
        const {data} = await apiClient.delete(`/users/${person_id}`)
        return data
      } catch (error) {
        const notification = {
          type: 'error',
          message: `getClients have error: ` + error.message
        }
        dispatch('notification/add', notification, {root: true})
      }
    },
    async getClientsByPages({commit, dispatch}, currentPage) {
      try {
        const {data} = await apiClient.get(currentPage)
        console.log(data, 'SET_PAGES')
        commit('SET_PAGES', data)
      } catch (error) {
        const notification = {
          type: 'error',
          message: `getClients have error: ` + error.message
        }
        dispatch('notification/add', notification, {root: true})
      }
    },
    async blockAccess({commit, dispatch}, {person_id}) {
      try {
        const res = await apiClient.post(`/block-access/${person_id}`)
        console.log(res, 'blockAccess')
      } catch (error) {
        const notification = {
          type: 'error',
          message: `blockAccess have error: ` + error.message
        }
        dispatch('notification/add', notification, {root: true})
      }
    },
    async searchClients({commit, dispatch}, searchData) {
      try {
        const {data} = await apiClient.get(`users/search?search=${searchData}`)
        commit('SET_PAGES', data)
      } catch (e) {

      }
    }
  },

  getters: {}
}

