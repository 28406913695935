import Vue from "vue";

export default Vue.extend({
  data: function () {
    return {
      answer: ['да', 'нет'],
      value: ''
    };
  },
  beforeMount() {
  },

  mounted() {
    Vue.nextTick(() => {

    });
  },

  created() {
    this.setAnswer(this.params.value)
    console.log(this.params.value)
  },
  methods: {
    getValue() {
      return this.value
    },
    setAnswer(answer) {
      this.value = answer
    }
  },
  template: `
    <multiselect
      ref="select"
      v-model="value"
      class="select select--application"
      :options="answer"
      :value="answer"
      :searchable="false"
      :allow-empty="false"
    />
  `
});
