<template>
  <div class="edit-ivideon">
    <div class="edit-ivideon__photos">
      <div
        class="edit-ivideon__photo"
      >
        <div
          v-if="!localPerson || !localPerson.avatar"
          class="photos-list__svg"
        >
          <photoSVG />
        </div>
        <img
          v-else
          class="photos-list__img"
          :src="localPerson.avatar"
          alt="person avatar"
        >
      </div>
      <div
        class="edit-ivideon__photo"
      >
        <div
          v-if="!localPerson || !localPerson.avatar"
          class="photos-list__svg"
        >
          <photoSVG />
        </div>
        <img
          v-else
          class="photos-list__img"
          :src="localPerson.avatar"
          alt="person avatar"
        >
      </div>
      <div
        class="edit-ivideon__photo"
      >
        <div
          v-if="!localPerson || !localPerson.avatar"
          class="photos-list__svg"
        >
          <photoSVG />
        </div>
        <img
          v-else
          class="photos-list__img"
          :src="localPerson.avatar"
          alt="person avatar"
        >
      </div>
    </div>
    <form
      action=""
    >
      <div class="about-staff edit-ivideon__about-staff">
        <div class="about-staff__column edit-ivideon__column">
          <div class="about-staff__left edit-ivideon__left">
            <app-input
              v-model="localPerson.person_ivideon_id"
              label="face_id"
              class-label="edit-staff__item focus-input"
              class-input="create-staff__value"
            />
            <app-input
              v-model="localPerson.face_gallery_id"
              label="face_gallery_id"
              class-label="edit-staff__item focus-input"
              class-input="create-staff__value create-staff__value--text-transform"
            />
          </div>

          <div class="about-staff__right">
            <app-input
              v-model="localPerson.terminal_name"
              label="Имя для терминала"
              class-label="edit-staff__item focus-input"
              class-input="create-staff__value"
            />
          </div>
        </div>
        <app-textarea
          id="comment"
          v-model="localPerson.comment"
          class-input="edit-staff__comment"
          class-label="edit-staff__item focus-input"
          placeholder="Введите текст комментария"
        >
          <slot>
            Комментарий
          </slot>
        </app-textarea>
        <div class="staff-buttons">
          <app-button
            v-if="!newStaff"
            class="button button--disabled"
            @click.prevent.stop.exact.native
          >
            Синхронизировать с iVideon
          </app-button>
          <app-button
            v-if="!newStaff"
            class="button button--disabled"
            @click.prevent.stop.exact.native
          >
            Удалить из iVideon
          </app-button>
          <slot name="save" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>

import AppInput from "../common/Input";
import AppButton from "../common/Button";
import AppTextarea from "../common/Textarea";
import photoSVG from "../../assets/images/photo.svg?inline.svg"

export default {
  name: "EditIVideon",
  components: {
    AppInput,
    AppTextarea,
    AppButton,
    photoSVG
  },
  props: {
    person: {
      type: Object,
      default: Object
    },
    newStaff: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      event: {
        person: ''
      }
    }
  },
  computed: {
    localPerson() {
      if (!this.newStaff) {
        return this.person
      } else {
        return this.event.person
      }
    }
  },
  watch: {
    'localPerson': {
      handler: function (newVal) {
        this.$emit('submit', newVal)
      },
      deep: true
    },
  },
  beforeMount() {
    this.event.person = this.person
  },
  methods: {}
}
</script>

<style scoped>

</style>
