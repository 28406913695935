<template>
  <section>
    <div class="wrapper-header">
      <div style="display: flex">
        <router-link
          class="button"
          to="/lists/staff"
        >
          Вернутся к списку персонала
        </router-link>
        <input
          id="filter-text-box"
          v-model="filterText"
          class="ag-theme-balham-search"
          type="text"
          placeholder="Поиск"
          @input="onFilterTextBoxChanged()"
        >
      </div>
      <app-pagination />
    </div>
    <ag-grid-vue
      style="width: 100%; height: 85vh;"
      :get-row-style="getRowStyle"
      :grid-options="gridOptions"
      :row-data="rowData"
      class="ag-theme-balham"
      :column-defs="columnDefs"
      :animate-rows="true"
      :row-heigh="rowHeight"
      :framework-components="frameworkComponents"
      :get-row-height="getRowHeight"
      @column-resized="onColumnResized"
      @grid-ready="onGridReady"
      @column-visible="onColumnVisible"
    />
  </section>
</template>

<script>
import AppPagination from "../components/Pagination";
import {AgGridVue} from "ag-grid-vue";
import EditStaffIvideon from "../components/ag-grid-components/EditStaffIvideon";
import PhotoIdIvideon from "../components/ag-grid-components/PhotoIdIvideon";


export default {
  name: "ListsStaffIvideon",
  components: {
    AppPagination,
    AgGridVue
  },
  data() {
    return {
      gridOptions: null,
      filterText: '',
      columnDefs: null,
      rowData: null,
      rowHeight: null,
      frameworkComponents: null,
      getRowStyle: null,
      showListIvieon: false
    }
  },
  computed: {
    persons() {
      return this.$store.state.persons.personsIdIvideon
    }
  },
  watch: {
    persons(idIvideon) {
      console.log(idIvideon)
      this.rowData = idIvideon
    }
  },
  created() {
    this.$store.dispatch('getAllPersonsIdIvideon')
  },
  async mounted() {
    this.gridApi = this.gridOptions.api
    this.gridColumnApi = this.gridOptions.columnApi
  },
  async beforeMount() {
    this.gridOptions = {}
    this.columnDefs = [
      {
        headerName: 'ФИО',
        field: 'name',
        lockPosition: true,
        width: 442
      },
      {
        headerName: 'ivedion_id',
        field: 'id_ivideon',
        width: 436,
        lockPosition: true
      },
      {
        headerName: 'Фото',
        cellRenderer: 'photoIdIvideonCellRenderer',
        field: 'url',
        width: 356,
        lockPosition: true,
      },
      {
        headerName: '',
        field: 'id_ivideon',
        cellRenderer: 'editStaffIvideonCellRenderer',
        lockPosition: true
      }
    ]
    this.frameworkComponents = {
      photoIdIvideonCellRenderer: PhotoIdIvideon,
      editStaffIvideonCellRenderer: EditStaffIvideon
    }
    this.getRowStyle = params => {
      if (params.node.rowIndex % 2 === 0) {
        return {
          background: '#FDFDFD',
          width: '100%',
          border: '1px solid #FBFBFB',
          borderRadius: '3px'
        }
      }
    }
    this.rowData = []
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.resize()
    },
    resize() {
      setTimeout(() => {
        this.gridApi.resetRowHeights();
      }, 100);
    },
    getRowHeight(params) {
      return params.node.rowHeight = 50;
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.filterText)
    },
    onColumnResized(params) {
      params.api.resetRowHeights()
    },
    onColumnVisible(params) {
      params.api.resetRowHeights()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
