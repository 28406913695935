<template>
  <div>
    <ul
      v-if="pages"
      class="page"
    >
      <li
        v-for="(link,index) in pages.links"
        :key="index"
        :class="{'page__numbers':true,'active':link.active}"
        @click="$emit('clickPage', link.url)"
        v-html="link.label"
      >
        {{ link.label }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    pages: {
      type: Object,
      required: false,
      default: null
    }
  },
}
</script>

<style scoped>

</style>
