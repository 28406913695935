import Vue from "vue";
import photoSVG from '../../assets/images/ListsStaff/photo-profile.svg'

export default Vue.extend({
  data: function () {
    return {
      rendererPhotos: null
    };
  },
  created() {
    this.updateImage(this.params.value);
  },
  methods: {
    updateImage(params) {
      this.rendererPhotos = params  ? params : photoSVG;
    },
    refresh(params) {
      this.params = params;
      this.updateImage(params);
    }
  },
  template: `<div  class="photo-staff"> <img  :src="rendererPhotos" alt="photo"/></div>`
});
