export default {
  methods: {
    goodsToTransaction(card = true, truePrice = true) {
      let data = {
        goods: [],
        totalSale: 0,
        account: card ? 'Расчетный счет' : 'Основная касса',
        client_id: this.$store.getters.clientByPhone.new_yc_id || null,
        person_id: this.$store.getters.clientByPhone.person_id,
        record_id: this.$store.getters.record.id || 0,
      };
      let goodCost = 0;
      this.$store.getters.selectedProducts.forEach((good) => {
        if (!truePrice){
          // goodCost = 1;
          goodCost = good.cost;
        } else {
          goodCost = good.cost;
        }
        data.totalSale += goodCost * good.count;
        data.goods.push({
          good_id: good.good_id,
          amount: good.count,
          cost_per_unit: goodCost,
          title: good.title,
          discount: 0
        });
      });
      return data;
    },

    registerCheckFiscal(NumDevice, TypeCheck, card) {
      // Подготовка данных команды, параметры (TypeCheck = 0, NumDevice = 0, InnKkm = "", CashierName = "")
      const Data = KkmServer.GetDataCheck(TypeCheck, NumDevice, "", "", "");

      //***********************************************************************************************************
      // ПОЛЯ ПОИСКА УСТРОЙСТВА
      //***********************************************************************************************************
      // Номер устройства. Если 0 то первое не блокированное на сервере
      Data.NumDevice = NumDevice;
      // ИНН ККМ для поиска. Если "" то ККМ ищется только по NumDevice,
      // Если NumDevice = 0 а InnKkm заполнено то ККМ ищется только по InnKkm
      Data.InnKkm = "";
      //---------------------------------------------
      // Заводской номер ККМ для поиска. Если "" то ККМ ищется только по NumDevice,
      Data.KktNumber = "";
      // **********************************************************************************************************

      // Время (сек) ожидания выполнения команды.
      //Если За это время команда не выполнилась в статусе вернется результат "NotRun" или "Run"
      //Проверить результат еще не выполненной команды можно командой "GetRezult"
      //Если не указано или 0 - то значение по умолчанию 60 сек.
      // Поле не обязательно. Это поле можно указывать во всех командах
      Data.Timeout = 30;
      // Это фискальный или не фискальный чек
      Data.IsFiscalCheck = true;
      // Тип чека, Тег 1054;
      // 0 – продажа/приход;                      10 – покупка/расход;
      // 1 – возврат продажи/прихода;             11 - возврат покупки/расхода;
      // 2 – корректировка продажи/прихода;       12 – корректировка покупки/расхода;
      Data.TypeCheck = TypeCheck;
      let printReceipt = JSON.parse(localStorage.getItem("checkedPaperReceipt"));
      // Не печатать чек на бумагу
      // Data.NotPrint = JSON.parse(localStorage.getItem("checkedPaperReceipt")) ?? true;
      // if (printReceipt){
        Data.NotPrint = true;
      // }

      // Data.NotPrint = true;

      // Data.NotPrint = false; //true,
      // Количество копий документа
      // Data.NumberCopies = 0;
      // Телефон или е-Майл покупателя, тег ОФД 1008
      // Если чек не печатается (NotPrint = true) то указывать обязательно
      // Формат: Телефон +{Ц} Email {С}@{C}
      Data.ClientAddress = JSON.parse(localStorage.getItem("emailClient")) ?? JSON.parse(localStorage.getItem("phoneClient")) ?? "pt.82@mail.ru";

      const products = this.goodsToTransaction(card).goods;
      if (products.length) {
        for (let idx in products) {
          // Добавление печати фискальной строки
          Data.AddRegisterString(
            // НаименованиеТовара(64 символа)
            products[idx].title,
            // Количество (3 знака после запятой)
            products[idx].amount,
            // ЦенаБезСкидки (2 знака после запятой)
            products[idx].cost_per_unit,
            // СуммаСтроки (2 знака после запятой)
            products[idx].cost_per_unit * products[idx].amount,
            // СтавкаНДС(0(НДС 0%), 10(НДС 10%), 20(НДС 20%), -1(НДС не облагается), 120 (НДС 20/120), 110 (НДС 10/110))
            -1,
            // Отдел
            0,
            // Код товара EAN13 - не обязательно
            "",
            // Признак способа расчета. тег ОФД 1214. Для ФФД.1.05 и выше обязательное поле
            // 1: "ПРЕДОПЛАТА 100% (Полная предварительная оплата до момента передачи предмета расчета)"
            // 2: "ПРЕДОПЛАТА (Частичная предварительная оплата до момента передачи предмета расчета)"
            // 3: "АВАНС"
            // 4: "ПОЛНЫЙ РАСЧЕТ (Полная оплата, в том числе с учетом аванса в момент передачи предмета расчета)"
            // 5: "ЧАСТИЧНЫЙ РАСЧЕТ И КРЕДИТ (Частичная оплата предмета расчета в момент его передачи с последующей оплатой в кредит )"
            // 6: "ПЕРЕДАЧА В КРЕДИТ (Передача предмета расчета без его оплаты в момент его передачи с последующей оплатой в кредит)"
            // 7: "ОПЛАТА КРЕДИТА (Оплата предмета расчета после его передачи с оплатой в кредит )"
            4,
            // Признак предмета расчета. тег ОФД 1212. Для ФФД.1.05 и выше обязательное поле
            // 1: "ТОВАР (наименование и иные сведения, описывающие товар)"
            // 2: "ПОДАКЦИЗНЫЙ ТОВАР (наименование и иные сведения, описывающие товар)"
            // 3: "РАБОТА (наименование и иные сведения, описывающие работу)"
            // 4: "УСЛУГА (наименование и иные сведения, описывающие услугу)"
            // 5: "СТАВКА АЗАРТНОЙ ИГРЫ (при осуществлении деятельности по проведению азартных игр)"
            // 6: "ВЫИГРЫШ АЗАРТНОЙ ИГРЫ (при осуществлении деятельности по проведению азартных игр)"
            // 7: "ЛОТЕРЕЙНЫЙ БИЛЕТ (при осуществлении деятельности по проведению лотерей)"
            // 8: "ВЫИГРЫШ ЛОТЕРЕИ (при осуществлении деятельности по проведению лотерей)"
            // 9: "ПРЕДОСТАВЛЕНИЕ РИД (предоставлении прав на использование результатов интеллектуальной деятельности или средств индивидуализации)"
            // 10: "ПЛАТЕЖ (аванс, задаток, предоплата, кредит, взнос в счет оплаты, пени, штраф, вознаграждение, бонус и иной аналогичный предмет расчета)"
            // 11: "АГЕНТСКОЕ ВОЗНАГРАЖДЕНИЕ (вознаграждение (банковского)платежного агента/субагента, комиссионера, поверенного или иным агентом)"
            // 12: "СОСТАВНОЙ ПРЕДМЕТ РАСЧЕТА (предмет расчета, состоящем из предметов, каждому из которых может быть присвоено вышестоящее значение"
            // 13: "ИНОЙ ПРЕДМЕТ РАСЧЕТА (предмет расчета, не относящемуся к предметам расчета, которым может быть присвоено вышестоящее значение"
            // 14: "ИМУЩЕСТВЕННОЕ ПРАВО" (передача имущественных прав)
            // 15: "ВНЕРЕАЛИЗАЦИОННЫЙ ДОХОД"
            // 16: "СТРАХОВЫЕ ВЗНОСЫ" (суммы расходов, уменьшающих сумму налога (авансовых платежей) в соответствии с пунктом 3.1 статьи 346.21 Налогового кодекса Российской Федерации)
            // 17: "ТОРГОВЫЙ СБОР" (суммы уплаченного торгового сбора)
            // 18: "КУРОРТНЫЙ СБОР"
            // 19: "ЗАЛОГ"
            1,
            //штрих - код маркировки товара со сканера(нужно настроить сканер так чтобы не проглатывал управляющие символы)
            //КИЗ(контрольный идентификационный знак) товарной номенклатуры Тег ОФД 1162(честный знак), можно не указывать
            // Поддерживаются ШК:
            // Без идентификатора экземпляра товара: EAN8, EAN13, ITF14
            // С идентификатором экземпляра товара: GS1, ШК шуб, ШК табачной продукции., ЕГАИС-2, ЕГАИС-3
            //Описание применимых ШК
            "",
            // Единица измерения предмета расчета. Можно не указывать
            "",
            // Цифровой код страны происхождения товара (CountryOfOrigin) в соответствии с Общероссийским классификатором стран мира 3 симв. Тег 1230
            "",
            // Регистрационный номер таможенной декларации (CustomsDeclaration) 32 симв. Тег 1231
            "",
            // Сумма акциза (ExciseAmount) с учетом копеек, включенная в стоимость предмета расчета Тег 1229
            null, //иначе сумма акциза
            // Дополнительный реквизит предмета расчета тег 1191, Только для ФФД 1.1 !
            ""
          );
        }
      }

      let services = this.$store.getters.selectedServices
      if (Object.keys(services).length) {
        let discount = 0;
        let costOfService = 0;
        for (let sId in services) {
          if (services[sId].discount) {
            discount = services[sId].discount / 100;
          } else {
            discount = 0;
          }
          if (services[sId].cost_per_unit) {
            costOfService = services[sId].cost_per_unit;
          } else {
            costOfService = services[sId].price;
          }
          // Добавление печати фискальной строки
          Data.AddRegisterString(
            // НаименованиеТовара(64 символа)
            services[sId].title,
            // Количество (3 знака после запятой)
            services[sId].count,
            // ЦенаБезСкидки (2 знака после запятой)
            costOfService - costOfService * discount,
            // СуммаСтроки (2 знака после запятой)
            services[sId].price,
            // СтавкаНДС(0(НДС 0%), 10(НДС 10%), 20(НДС 20%), -1(НДС не облагается), 120 (НДС 20/120), 110 (НДС 10/110))
            -1,
            // Отдел
            0,
            // Код товара EAN13 - не обязательно
            "",
            // Признак способа расчета. тег ОФД 1214. Для ФФД.1.05 и выше обязательное поле
            // 1: "ПРЕДОПЛАТА 100% (Полная предварительная оплата до момента передачи предмета расчета)"
            // 2: "ПРЕДОПЛАТА (Частичная предварительная оплата до момента передачи предмета расчета)"
            // 3: "АВАНС"
            // 4: "ПОЛНЫЙ РАСЧЕТ (Полная оплата, в том числе с учетом аванса в момент передачи предмета расчета)"
            // 5: "ЧАСТИЧНЫЙ РАСЧЕТ И КРЕДИТ (Частичная оплата предмета расчета в момент его передачи с последующей оплатой в кредит )"
            // 6: "ПЕРЕДАЧА В КРЕДИТ (Передача предмета расчета без его оплаты в момент его передачи с последующей оплатой в кредит)"
            // 7: "ОПЛАТА КРЕДИТА (Оплата предмета расчета после его передачи с оплатой в кредит )"
            4,
            // Признак предмета расчета. тег ОФД 1212. Для ФФД.1.05 и выше обязательное поле
            // 1: "ТОВАР (наименование и иные сведения, описывающие товар)"
            // 2: "ПОДАКЦИЗНЫЙ ТОВАР (наименование и иные сведения, описывающие товар)"
            // 3: "РАБОТА (наименование и иные сведения, описывающие работу)"
            // 4: "УСЛУГА (наименование и иные сведения, описывающие услугу)"
            // 5: "СТАВКА АЗАРТНОЙ ИГРЫ (при осуществлении деятельности по проведению азартных игр)"
            // 6: "ВЫИГРЫШ АЗАРТНОЙ ИГРЫ (при осуществлении деятельности по проведению азартных игр)"
            // 7: "ЛОТЕРЕЙНЫЙ БИЛЕТ (при осуществлении деятельности по проведению лотерей)"
            // 8: "ВЫИГРЫШ ЛОТЕРЕИ (при осуществлении деятельности по проведению лотерей)"
            // 9: "ПРЕДОСТАВЛЕНИЕ РИД (предоставлении прав на использование результатов интеллектуальной деятельности или средств индивидуализации)"
            // 10: "ПЛАТЕЖ (аванс, задаток, предоплата, кредит, взнос в счет оплаты, пени, штраф, вознаграждение, бонус и иной аналогичный предмет расчета)"
            // 11: "АГЕНТСКОЕ ВОЗНАГРАЖДЕНИЕ (вознаграждение (банковского)платежного агента/субагента, комиссионера, поверенного или иным агентом)"
            // 12: "СОСТАВНОЙ ПРЕДМЕТ РАСЧЕТА (предмет расчета, состоящем из предметов, каждому из которых может быть присвоено вышестоящее значение"
            // 13: "ИНОЙ ПРЕДМЕТ РАСЧЕТА (предмет расчета, не относящемуся к предметам расчета, которым может быть присвоено вышестоящее значение"
            // 14: "ИМУЩЕСТВЕННОЕ ПРАВО" (передача имущественных прав)
            // 15: "ВНЕРЕАЛИЗАЦИОННЫЙ ДОХОД"
            // 16: "СТРАХОВЫЕ ВЗНОСЫ" (суммы расходов, уменьшающих сумму налога (авансовых платежей) в соответствии с пунктом 3.1 статьи 346.21 Налогового кодекса Российской Федерации)
            // 17: "ТОРГОВЫЙ СБОР" (суммы уплаченного торгового сбора)
            // 18: "КУРОРТНЫЙ СБОР"
            // 19: "ЗАЛОГ"
            4,
            //штрих - код маркировки товара со сканера(нужно настроить сканер так чтобы не проглатывал управляющие символы)
            //КИЗ(контрольный идентификационный знак) товарной номенклатуры Тег ОФД 1162(честный знак), можно не указывать
            // Поддерживаются ШК:
            // Без идентификатора экземпляра товара: EAN8, EAN13, ITF14
            // С идентификатором экземпляра товара: GS1, ШК шуб, ШК табачной продукции., ЕГАИС-2, ЕГАИС-3
            //Описание применимых ШК
            "",
            // Единица измерения предмета расчета. Можно не указывать
            "",
            // Цифровой код страны происхождения товара (CountryOfOrigin) в соответствии с Общероссийским классификатором стран мира 3 симв. Тег 1230
            "",
            // Регистрационный номер таможенной декларации (CustomsDeclaration) 32 симв. Тег 1231
            "",
            // Сумма акциза (ExciseAmount) с учетом копеек, включенная в стоимость предмета расчета Тег 1229
            null, //иначе сумма акциза
            // Дополнительный реквизит предмета расчета тег 1191, Только для ФФД 1.1 !
            ""
          );
        }
      }

      if (card) {
        // Если надо одновременно автоматически провести транзакцию через эквайринг
        // Эквайринг будет задействован если: 1. чек фискальный, 2. оплата по "ElectronicPayment" не равна 0, 3. PayByProcessing = true
        // Использовать эквайринг: Null - из настроек на сервере, false - не будет, true - будет
        Data.PayByProcessing = true; //В тестовом чеке автоматический эквайринг выключен
        // Номер устройства для эквайринга - Null - из настроек на сервере, 0 - любое, число - номер конкретного устройства
        Data.NumDeviceByProcessing = 0;
        // Номер чека для эквайринга
        Data.ReceiptNumber = 'CHECK-' + Math.floor(Math.random() * (999999 - 1 + 1)) + 1;
        // Печатать Слип-чек после чека (а не в чеке)
        Data.PrintSlipAfterCheck = true;
        // Печатать Слип-чек дополнительно для кассира (основной слип-чек уже будет печататся в составе чека)
        Data.PrintSlipForCashier = false;
        Data.ElectronicPayment  = this.fullPrice;
      } else {
        Data.PayByProcessing = false;
        Data.Cash  = this.fullPrice;
      }

      // Вызов команды
      Data.Execute(this.executeSuccess);

      // Возвращается JSON:
      //{
      //    "CheckNumber": 3,           // Номер документа
      //    "SessionNumber": 1,         // Номер смены
      //    "SessionCheckNumber": 1,    // Номер чека в смене
      //    "URL": "https://ofd-ya.ru/getFiscalDoc?kktRegId=0000000000061716&fiscalSign=839499349",
      //    "QRCode": "t=20190101T195300&s=0.03&fn=9999078900002838&i=3&fp=839499349&n=1",
      //    "Command": "RegisterCheck",
      //    "Cash": 0, // Оплачено наличными
      //    "ElectronicPayment": 3.02, // Оплачено электронноо
      //    "AdvancePayment": 0, // Оплачено предоплатой (зачетом аванса)
      //    "Credit": 0, // постоплатой(в кредит)
      //    "CashProvision": 0, // встречным предоставлением (сертификаты, др. мат.ценности)
      //    "Error": "", // Текст ошибки если была - обязательно показать пользователю - по содержанию ошибки можно в 90% случаях понять как ее устранять
      //    "Message": "", // Сообщение пользователю - Если строка не пустая - ее нужно отобразить пользователю
      //    "Status": 0, // Ok = 0, Run(Запущено на выполнение) = 1, Error = 2, NotFound(устройство не найдено) = 3, NotRun = 4
      //    "IdCommand": "dd261969-4190-1125-26cd-aaf5c213c0e3",
      //    "NumDevice": 2
      //}
    },
  },

  computed: {
    fullPrice() {
      let fullPrice = this.goodsToTransaction().totalSale;
      let services = this.$store.getters.selectedServices
      if (Object.keys(services).length) {
        let costOfService = 0;
        for (let sId in services) {
          // costOfService = services[sId].cost_per_unit ? services[sId].cost_per_unit : services[sId].price;
          // fullPrice += costOfService * services[sId].count;
          fullPrice += services[sId].price;
        }
      }
      return fullPrice;
    },

    servicesArePresent() {
      return Boolean(Object.keys(this.$store.getters.selectedServices).length);
    },

    goodsArePresent() {
      return Boolean(this.goodsToTransaction().goods.length);
    }
  }
}
