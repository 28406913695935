export default {
  data() {
    return {
      kkmErrorsText: [
        ['В ККТ отсутствует бумага!', 'В принтере закончилась бумага<br> или произошло её замятие.']
      ]
    }
  },

  methods: {
    kkmErrorText(error) {
      const err = this.kkmErrorsText.find(item => item[0] === error);
      if (err) {
        return err[1];
      }
      return error;
    }
  },

  computed: {
    unpaidAndOneGood() {
      return ((this.$store.getters.unpaidRecords.length || Object.keys(this.$store.getters.record).length) && this.$store.getters.selectedProducts.length);
    }
  }
}
