<template>
  <div
    id="app"
  >
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>

export default {
  name: 'App',
  computed: {
    layout() {
      return this.$route.meta.layout || 'default-layout'
    }
  },
}
</script>

<style lang="scss">
@import '../node_modules/ag-grid-community/dist/styles/ag-grid.scss';
@import '../node_modules/ag-grid-community/dist/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss';
</style>
