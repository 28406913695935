<template>
  <div class="comments-history">
    <div class="comments-history__date" />
    <div class="comments-history__item">
      <div class="comments-history__avatar" />
      <div class="comments-history__name" />
      <span class="comments-history__time" />
      <div class="comments-history__comment" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Comments"
}
</script>

<style scoped>

</style>
