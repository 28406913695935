<template>
  <main class="main">
    <div class="container main__container">
      <h1
        v-if="currentClient().id"
        class="main__title"
      >
        Привет, {{ currentClient().firstname }}!
      </h1>
      <h3
        v-if="currentClient().id"
        class="main__subtitle"
      >
        Добро пожаловать в Стрижевский!
      </h3>
      <h3
        v-else
        class="main__subtitle"
      >
        Совпадений с таким телефоном не обнаружено.
      </h3>

      <div class="buttons buttons__container">
        <div
          class="buttons__button button button--right"
          @click="notMe"
        >
          <div class="button__inner">
            <p style="font-size:22px" class="button__inner-title button__inner-title--black">
              Это не я
              <br/>
              Войти по номеру телефона
            </p>
          </div>
        </div>
        <div
          class="button button--right"
          @click="next"
        >
          <div class="button__inner">
            <p class="button__inner-title button__inner-title--black">
              Далее
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "Hello",

  data() {
    return {}
  },

  computed: {},

  methods: {
    notMe() {
      this.$router.push('/terminal/phone');
    },

    next() {
      //this.$router.push('/terminal/choose-services');
      this.$router.push('/terminal/search-unpaid-records');
    },

    currentClient() {
      return this.$store.getters.clientByPhone;
    }
  },

  mounted() {
    console.log('client', this.$store.getters.clientByPhone)
  },


}
</script>

<style scoped>

</style>
