<template>
  <main class="receipt-main">
    <div class="container">
      <div class="receipt-card">
      <h1 class="receipt-card__title">Получить электронный чек</h1>
        <div>
        <span :style="`background: url(${require('@/assets/terminal/images/dest/email.svg')}) no-repeat`" class="receipt-card__subtitle">Эл. почта</span>
        <span class="receipt-card__subtitle-text">Или введите ваш адрес электронной почты</span>
        </div>
      <input @click="showEditEmail" v-model="email"  class="input-email" type="text">
        <p v-if="errors.email" class="valid__email">
          {{errors.email}}
        </p>
        <div class="text__info">
        <p >Чтобы получить электронный кассовый чек введите свою электронную почту.</p>
        <p >В противном случае чек будет показан на экране (или вышлем по дополнительному запросу)</p>
        </div>
        <div v-show="$store.getters.loggedIn.department_data.send_receipt" class="receipt-card_send-text">
        <div  class="">Копия вашего эл. чека находится здесь</div>
          <input disabled class="copy-receipt" v-model="$store.getters.loggedIn.department_data.send_receipt" type="text">
        </div>
        <div v-if="$store.getters.loggedIn.department_data.use_paper_receipt" style=""   class=" receipt-card_paper">
          <input style="float: right;"  type="checkbox" id="checkbox" v-model="chbox"  @click="changeChBox">
          <label style="float: right; margin-top: 5px" for="checkbox">Получить бумажный чек</label>
        </div>
      </div>
      <div class="btn-receipt" >
      <div class="main-services__buttons">
        <div  @click="$router.push('/terminal/menu-tmp')"
             class="button button--left">
          <div class="button__inner">
            <p class="button__inner-title button__inner-title--light">
              Назад
            </p>
          </div>
        </div>

        <div

          class="button button--right"
          @click=""
        >
          <div class="button__inner" @click="next">
            <p class="button__inner-title button__inner-title--black">
              Далее
            </p>
          </div>
        </div>
      </div>
    </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'SendReceipt',
  data () {
    return {
      editEmail: '',
      errors: {
        email: null
      },
      chbox: false,
      email: JSON.parse(localStorage.getItem("emailClient")) ?? this.$store.getters.clientByPhone.email  ?? null
    }
  },

  mounted() {
    window.addEventListener('click', e => {
      if (e.target.className === 'keyboard__item')
        this.addChar(e.target.innerText)
    });
    localStorage.setItem("checkedPaperReceipt", JSON.stringify(!this.chbox));
  },
  computed: {
    emailInput () {
      if (this.editEmail.length) {}
    }
  },
  methods: {
    showEditEmail () {
      this.$router.push('/terminal/edit-email')
    },
    addChar(char) {
      if (this.editEmail.length)
        char = char.toLowerCase()
      this.editEmail += char.toString();
      this.email = this.editEmail
    },
    isEmailValid () {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.email);
    },
    next () {
      if (this.email) {
        if (!this.isEmailValid(this.email)) {
          this.errors.email = 'email имеет неверный формат';
          return
        }
        localStorage.setItem("emailClient", JSON.stringify(this.email) ?? '');
      }
     this.$router.push('/terminal/choose-pay-method')
    },
    changeChBox () {
     localStorage.setItem("checkedPaperReceipt", JSON.stringify(this.chbox));
    }
  }
}
</script>

<style scoped lang="scss">
.text-info {

}
.name-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1px;
}
.receipt-card {
  .valid__email {
    color: red;
    font-size: 18px;
    margin-top: -20px;
  }
  .text__info {
    font-family: 'Montserrat';
    color: #4FD8DB;
    font-size: 16px;
    margin-top: -25px;
    text-align: center;
  }
  position: relative;
  height: 553px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //.container {
  //  padding-top: 206px;
  //}

  .card__title {
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 59px;
    text-align: center;
    color: #4FD8DB;
  }

  .subtitle {
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #4FD8DB;
    //margin-bottom: 63px;
  }

  .icon {
    margin: 0 auto;
  }
}

.receipt-main {
  .btn-receipt{
    margin-top: 100px;
    p {
      margin-top: -10px;
    }
  }

  .input-email {
    width: 735px;
    height: 100px;
    background: rgba(50, 149, 162, 0.1);
    border: none;
    margin-bottom: 40px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 10px;

    &:focus {
      outline: 0;
    }

  }
  .container {
    //padding-top: 100px;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .receipt-card__subtitle{
    color: #17324D;
   font-family: Arial;
    font-size: 24px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 8px;
    padding-bottom: 8px;
    //background-color: white;
    //background-image: url("/src/assets/terminal/images/dest/email.svg");
    //background-size: contain;
  }
  .receipt-card__subtitle-text {
    color: #4FD8DB;
    font-family: Montserrat;
    font-size: 24px;
    margin-left: 27px;

  }
  .receipt-card_paper{
    bottom: -50px;

    //display: flex;
    ////flex-direction: row;
    //justify-content: end;
    //align-items: center;
    position: absolute;
    color: #4FD8DB;
    width: 100%;
    font-size: 18px;
    input {
      width: 30px;
      height: 30px;
      margin-left: 10px;
      //display: none;
      background: #4FD8DB !important;
      //&:checked ~ span {
      //  background: #cccccc !important;
      //}
    }
  }
  .receipt-card_send-text {
    color: #4FD8DB;
    font-family: Montserrat;
    font-size: 18px;
    width: 100%;
    margin-left: 337px;

    .copy-receipt{
      width: 376px;
      height: 46px;
      background: rgba(50, 149, 162, 0.1);
      border: none;
      margin-bottom: 40px;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 5px;
      color: #CAD1D4;
      font-size: 24px;
    }
  }
  .receipt-card__title {
    position: relative;
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 59px;
    text-align: center;
    color: #4FD8DB;
    margin-bottom: 50px;
    margin-top: 50px;
  }

  .subtitle {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 19px;
  }

  .value {
    display: block;
    margin: 0 auto 75px;
  }
}
</style>
