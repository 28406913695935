import Vue from 'vue'
import VueRouter from 'vue-router'
import Reports from '../views/Reports'
import Schedule from '../views/Schedule'
import Lists from '../views/Lists'
import ListsStaff from "../views/ListsStaff";
import ListsStaffIvideon from "../views/ListsStaffIvideon";
import EditStaff from "../views/EditStaff";
import ListsClients from "../views/ListsClients";
import ReportsStaff from "../views/ReportsStaff";
import ReportsClients from "../views/ReportsClients";
import CreateStaffIvideon from "../views/CreateStaffIvideon";
import Authorization from "../views/Authorization";
import EditClient from "../views/EditClient";
import cookie from "js-cookie";
import CallCenter from "../views/CallCenter";
import CallCenterFirstVisit from "../views/CallCenterFirstVisit";
import Phone from "../views/Terminal/Phone";
import Hello from "../views/Terminal/Hello";
import ChooseServices from "../views/Terminal/ChooseServices";
import NewStaff from "../views/NewStaff";
import ChooseMaster from "../views/Terminal/ChooseMaster";
import Done from "../views/Terminal/Done";
import ChoosePayMethod from "../views/Terminal/ChoosePayMethod";
import Name from "../views/Terminal/Name";
import Unrecognized from "../views/Terminal/Unrecognized";
import Home from "../views/Terminal/Home";
import PayByCash from "../views/Terminal/PayByCash";
import PayByCard from "../views/Terminal/PayByCard";
import DepartmentSettings from "../views/DepartmentSettings";
import Records from "../views/Terminal/Records";
import SearchUnpaidRecords from "../views/Terminal/SearchUnpaidRecords";
import Barcode from "../views/Terminal/Barcode";
import Products from "../views/Terminal/Products";
import ReportsT from "../views/Terminal/Reports";
import Menu from "../views/Terminal/Menu";
import MenuTmp from "../views/Terminal/MenuTmp";
import SendReceipt from "../views/Terminal/SendReceipt";
import PrintReceipt from "../views/Terminal/PrintReceipt";
import EditEmail from "@/views/Terminal/EditEmail";
import Categories from "@/views/Terminal/Categories";

Vue.use(VueRouter)

const routes = [
  {
    path: '/authorization',
    name: 'Авторизация',
    component: Authorization,
    meta: {
      layout: 'auth-layout'
    }
  },
  {
    path: '/',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lists',
    name: 'Списки',
    component: Lists,
    redirect: '/lists/staff',
    props: {img: 'lists.svg', margin: 125},
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lists/staff',
    name: 'ListsStaff',
    component: ListsStaff,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lists/clients',
    name: 'ListsClients',
    component: ListsClients,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lists/clients/:id',
    name: 'ListsClientsID',
    component: EditClient,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lists/staff/ivideon',
    name: 'List-staff-ivideon',
    component: ListsStaffIvideon,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lists/staff/ivideon/:id',
    name: 'List-staff-ivideon/id',
    component: CreateStaffIvideon,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lists/staff/new-staff',
    component: NewStaff,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/lists/staff/:id',
    component: EditStaff,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reports',
    name: 'Отчеты',
    img: 'reports.svg',
    margin: 175,
    component: Reports,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reports/staff',
    name: 'Персонал',
    component: ReportsStaff,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reports/clients',
    name: 'Клиенты',
    component: ReportsClients,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/schedule',
    name: 'Расписание',
    img: 'schedule.svg',
    margin: 225,
    component: Schedule,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/call-center',
    name: 'Контакт Центр',
    img: 'schedule.svg',
    component: CallCenter,
    redirect: '/call-center/first-visit',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/call-center/first-visit',
    name: 'После первого визита',
    img: 'schedule.svg',
    component: CallCenterFirstVisit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/department/:id',
    name: 'Настройки салона',
    component: DepartmentSettings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/terminal/phone',
    name: 'Phone',
    component: Phone,
    meta: {
      layout: 'terminal-layout',
      requiresAuth: true
    }
  },
  {
    path: '/terminal/hello',
    name: 'Hello',
    component: Hello,
    meta: {
      layout: 'terminal-layout',
      requiresAuth: true
    }
  },
  {
    path: '/terminal/choose-services',
    name: 'ChooseServices',
    component: ChooseServices,
    meta: {
      layout: 'terminal-layout',
      requiresAuth: true
    }
  },
  {
    path: '/terminal/choose-master',
    name: 'ChooseMaster',
    component: ChooseMaster,
    meta: {
      layout: 'terminal-layout',
      requiresAuth: true
    }
  },
  {
    path: '/terminal/done',
    name: 'Done',
    component: Done,
    meta: {
      layout: 'terminal-layout',
      requiresAuth: true
    }
  },
  {
    path: '/terminal/choose-pay-method',
    name: 'ChoosePayMethod',
    component: ChoosePayMethod,
    meta: {
      layout: 'terminal-layout',
      requiresAuth: true
    }
  },
  {
    path: '/terminal/name',
    name: 'Name',
    component: Name,
    meta: {
      layout: 'terminal-layout',
      requiresAuth: true
    }
  },
  {
    path: '/terminal/unrecognized',
    name: 'Unrecognized',
    component: Unrecognized,
    meta: {
      layout: 'terminal-layout',
      requiresAuth: true
    }
  },
  {
    path: '/terminal/home',
    name: 'Home',
    component: Home,
    meta: {
      layout: 'terminal-layout',
      requiresAuth: true
    }
  },
  {
    path: '/terminal/pay-by-cash',
    name: 'PayByCash',
    component: PayByCash,
    meta: {
      layout: 'terminal-layout',
      requiresAuth: true
    }
  },
  {
    path: '/terminal/pay-by-card',
    name: 'PayByCard',
    component: PayByCard,
    meta: {
      layout: 'terminal-layout',
      requiresAuth: true
    }
  },
  {
    path: '/terminal/records',
    name: 'Records',
    component: Records,
    meta: {
      layout: 'terminal-layout',
      requiresAuth: true
    }
  },
  {
    path: '/terminal/search-unpaid-records',
    name: 'SearchUnpaidRecords',
    component: SearchUnpaidRecords,
    meta: {
      layout: 'terminal-layout',
      requiresAuth: true
    }
  },
  {
    path: '/terminal/barcode',
    name: 'Barcode',
    component: Barcode,
    meta: {
      layout: 'terminal-layout',
      requiresAuth: true
    }
  },
  {
    path: '/terminal/products',
    name: 'Products',
    component: Products,
    meta: {
      layout: 'terminal-layout',
      requiresAuth: true
    }
  },
  {
    path: '/terminal/reports',
    name: 'Reports',
    component: ReportsT,
    meta: {
      layout: 'terminal-layout',
      requiresAuth: true
    }
  },
  {
    path: '/terminal/menu',
    name: 'Menu',
    component: Menu,
    meta: {
      layout: 'terminal-layout',
      requiresAuth: true
    }
  },
  {
    path: '/terminal/menu-tmp',
    name: 'MenuTmp',
    component: MenuTmp,
    meta: {
      layout: 'terminal-layout',
      requiresAuth: true
    }
  },
  {
    path: '/terminal/send-receipt',
    name: 'SendReceipt',
    component: SendReceipt,
    meta: {
      layout: 'terminal-layout',
      requiresAuth: true
    }
  },
  {
    path: '/terminal/print-receipt',
    name: 'PrintReceipt',
    component: PrintReceipt,
    meta: {
      layout: 'terminal-layout',
      requiresAuth: true
    }
  },
  {
    path: '/terminal/edit-email',
    name: 'EditEmail',
    component: EditEmail,
    meta: {
      layout: 'terminal-layout',
      requiresAuth: true
    }
  },
  {
    path: '/terminal/categories',
    name: 'Categories',
    component: Categories,
    meta: {
      layout: 'terminal-layout',
      requiresAuth: true
    }
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = cookie.get('jwt') && cookie.get('user')
  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    // next('/authorization')
    location.href = '/authorization'
  }
  next()
})
export default router
