<template>
  <section>
    <div class="wrapper-header">
      <div style="display: flex;">
        <router-link
          exact
          class="button button--add add-user-link"
          to="/lists/clients"
        >
          Создать персону
        </router-link>
        <app-input
          id="filter-text-box"
          v-model="filterText"
          class-label="ag-theme-balham-search-label"
          class-input="ag-theme-balham-search"
          type="text"
          placeholder="Поиск"
          @input="onFilterTextBoxChanged()"
        >
          <button
            class="ag-theme-balham-search-clean"
            @click="cleanSearch"
          />
        </app-input>
      </div>
      <app-pagination
        v-if="clientsPages"
        :pages="clientsPages"
        @clickPage="getClientsPages"
      />
    </div>
    <ag-grid-vue
      style="width: 100%; height: 85vh;"
      :get-row-style="getRowStyle"
      :grid-options="gridOptions"
      :default-col-def="defaultColDef"
      :row-data="rowData"
      :animate-rows="true"
      class="ag-theme-balham"
      :column-defs="columnDefs"
      :row-heigh="rowHeight"
      :framework-components="frameworkComponents"
      @on-column-visible="onColumnVisible"
      @column-resized="onColumnResized"
      @grid-ready="onGridReady"
    />
  </section>
</template>


<script>
import {AgGridVue} from 'ag-grid-vue'
import AppPagination from '../components/Pagination'
import AppInput from "../components/common/Input";
import EditClients from "../components/ag-grid-components/EditClients";


export default {
  name: 'ListsClient',
  components: {
    AppPagination,
    AgGridVue,
    AppInput
  },
  data() {
    return {
      currentPage: '/users?page=1',
      defaultColDef: null,
      clientsPages: null,
      gridOptions: null,
      filterText: '',
      columnDefs: null,
      rowData: null,
      rowHeight: null,
      getRowStyle: null,
      frameworkComponents: null
    }
  },
  computed: {
    clients() {
      const {clients} = this.$store.state.clients
      return clients
    },
    pages() {
      const {data} = this.$store.state.clients.pages
      return data
    }
  },
  watch: {
    clients(clients) {
      this.rowData = clients
    },
    pages(newPage) {
      this.clientsPages = newPage
      this.rowData = newPage.data
    }
  },
  created() {
    this.$store.dispatch('getClientsByPages', this.currentPage)
  },
  async beforeMount() {
    this.gridOptions = {}
    this.columnDefs = [
      {
        headerName: 'Имя',
        field: 'firstname',
        cellEditor: 'editClientCellRenderer',
        editable: true,
        lockPosition: true,
        width: 194
      },
      {
        headerName: 'Фамилия',
        field: 'lastname',
        cellEditor: 'editClientCellRenderer',
        editable: true,
        width: 235,
        lockPosition: true
      },
      {
        headerName: 'Отчество',
        field: 'fatherland',
        cellEditor: 'editClientCellRenderer',
        editable: true,
        width: 172,
        lockPosition: true
      },
      {
        headerName: 'ivideon_id',
        field: 'ivideon_id',
        lockPosition: true,
        cellEditor: 'editClientCellRenderer',
        editable: true,
        width: 164
      },
      {
        headerName: 'tg_id',
        field: 'tg_id',
        lockPosition: true,
        cellEditor: 'editClientCellRenderer',
        editable: true,
        width: 135
      },
      {
        headerName: 'Телефон',
        field: 'phone',
        cellEditor: 'editClientCellRenderer',
        editable: true,
        lockPosition: true,
        width: 187,
      },
      {
        headerName: 'Точка последнего визита',
        field: 'department_name',
        cellEditor: 'editClientCellRenderer',
        editable: true,
        lockPosition: true,
        minWidth: 240,
      },
    ]
    this.frameworkComponents = {
      editClientCellRenderer: EditClients
    }
    this.defaultColDef = {
      flex: 1
    }
    this.getRowStyle = params => {
      if (params.node.rowIndex % 2 === 0) {
        return {
          background: '#FDFDFD',
          border: '1px solid #FBFBFB',
          borderRadius: '3px'
        }
      }
    }
    this.rowData = []
  },
  methods: {
    cleanSearch() {
      this.filterText = ''
      this.getClientsPages(this.currentPage)
    },
    getClientsPages(currentPage) {
      if (currentPage) {
        this.$store.dispatch('getClientsByPages', currentPage)
      }
      /* this.$router.push(`/lists/clients/page=${currentPage}`)*/

    },
    onFilterTextBoxChanged() {
      this.$store.dispatch('searchClients', this.filterText)
    },
    onColumnResized(params) {
      params.api.resetRowHeights()
    },
    onColumnVisible(params) {
      console.log(params, 'onColumnVisible')
      params.api.resetRowHeights()
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      console.log(params, 'onGridReady')
      this.resize()
    },
    resize() {
      setTimeout(() => {
        this.gridApi.resetRowHeights();
      }, 100);
    },


  }
}
</script>

<style lang="scss">
.add-user-link {
  display: inline-block;
}
</style>
