import Vue from "vue";
import photoSVG from '../../assets/images/ListsStaff/photo-profile.svg'

export default Vue.extend({
  data: function () {
    return {
      rendererPhotos: null
    };
  },
  created() {
    let {data:{photos}}=this.params
    photos.splice(4)
    this.updateImage(this.params.data.photos);
  },
  methods: {
    updateImage(params) {
      this.rendererPhotos = params.length !==0 ? params : photoSVG;
    },
    refresh(params) {
      this.params = params;
      this.updateImage(params);
    }
  },
  template: `<div class="photo-staff-wrap"><div v-for="photo in rendererPhotos" :key="photo" class="photo-staff hover-photo"> <img  :src="photo" alt="photo"/></div></div>`
});
