export  const namespaced=true

export const state={
  notification:[]
}

let nextId=1
export const mutations = {
  PUSH(state,notification){
    state.notification.push({
      ...notification,
      id:nextId++
    })
  },
  DELETE(state,notitficationToRemove){
    state.notifications= state.notifications.filter(
      notification=>notification.id !== notitficationToRemove.id
    )
  }
}
export const actions={
  add({commit},notification){
    commit('PUSH',notification)
  },
  remove({commit},notitficationToRemove){
    commit('DELETE',notitficationToRemove)
  }
}
