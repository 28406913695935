<template>
  <section class="reports">
    <main class="reports__article" />
  </section>
</template>

<script>

export default {
  name: 'Reports',
  data() {
    return {}
  }
}
</script>

<style scoped>
.reports {
  display: flex;
  width: 100%;
}

.reports__article {
  width: 100%;
}

</style>
