<template>
  <section>
    <app-button-add
      style="margin-left: 26px;margin-bottom:29px"
      :title="'Добавить отчет'"
    />
    <the-reports-clients-form style="margin-top: 40px" />
    <app-button style="margin-left: 46px;margin-top: 40px">
      Сохранить
    </app-button>
  </section>
</template>

<script>
import AppButtonAdd from '../components/common/ButtonAdd'
import TheReportsClientsForm from '../components/ReportsClientsForm'
import AppButton from '../components/common/Button'

export default {
  name: 'ReportsStaff',
  components: {
    AppButtonAdd,
    TheReportsClientsForm,
    AppButton
  }
}
</script>

<style scoped>

</style>
