import Vue from "vue";
import phoneSVG from '../../assets/images/CallCenter/phone.svg';
import {apiClient} from "../../services/api";

export default Vue.extend({
  data: function () {
    return {
      firstname: null,
      id: null
    };
  },
  beforeMount() {
  },
  mounted() {
  },
  created() {
    this.firstname = this.params.data.firstname
    this.id = this.params.data
  },
  methods: {
    showModal() {
      apiClient.post('/set-in-work-one-record', {
        record_id: this.id.record_id
      })
      this.$store.commit('CHANGE_SHOW_MODAL', true);
      this.$store.commit('SET_EDITING_ID', this.id);
    }
  },
  template: `
    <div @click="showModal" class="toCallModal-cell">
    <div style="margin-right: 9px;">
      <button class="edit-person"><img class="call-center-phone-icon" src="${phoneSVG}" alt="edit">
      </button>
    </div>
    <div>
      {{ firstname }}
    </div>
    </div>

  `
});
