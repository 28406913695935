<template>
  <section style="display: flex; height: 92vh">
    <section style="width: 100%; height: 92vh;">
      <div class="wrapper-header">
        <div style="display: flex;">
          <app-input
            id="filter-text-box"
            v-model="filterText"
            class-input="ag-theme-balham-search"
            type="text"
            placeholder="Поиск"
            @input="onFilterTextBoxChanged"
          />
          <input
            ref="eInput"
            class="ag-custom-input-data"
            type="date"
            placeholder="yyyy-mm-dd"
            @input="getDataFilterStart"
          >
          <input
            ref="eInput"
            class="ag-custom-input-data"
            type="date"
            placeholder="yyyy-mm-dd"
            @input="getDataFilterFinish"
          >
        </div>
        <app-pagination
          v-if="clientsPages"
          :pages="clientsPages"
          @clickPage="getUsersOneRecordByPages"
        />
      </div>
      <ag-grid-vue
        :get-row-style="getRowStyle"
        :grid-options="gridOptions"
        :default-col-def="defaultColDef"
        :row-data="rowData"
        :row-style="rowStyle"
        style="width: 100%; height: 85vh;"
        class="ag-theme-balham ag-theme-balham--call-center"
        :column-defs="columnDefs"
        :row-heigh="rowHeight"
        :framework-components="frameworkComponents"
        :animate-rows="true"
        :cell-editing-stopped="onCellEditingStopped"
        :is-external-filter-present="isExternalFilterPresent"
        :does-external-filter-pass="doesExternalFilterPass"
        @grid-ready="onGridReady"
        @column-resized="onColumnResized"
        @cell-value-changed="onCellValueChanged"
      />
      <app-client-modal
        v-show="isModalVisible"
        @close="closeModal"
      />
    </section>
  </section>
</template>
<!-- :stop-editing-when-grid-loses-focus="true"-->

<script>
import {AgGridVue} from 'ag-grid-vue'
import AppPagination from '../components/Pagination'
import AppInput from "../components/common/Input";
import ApplicationEditor from "../components/ag-grid-components/ApplicationEditor";
import CallCenterToCallIcon from "../components/ag-grid-components/CallCenterToCallIcon";
import CallCenterToCallModal from "../components/ag-grid-components/CallCenterToCallModal";
import AppClientModal from "../components/CallCenter/ClientModal";
import {apiClient, echo} from "../services/api";


export default {
  name: 'CallCenterFirstVisit',
  components: {
    AppClientModal,
    AppPagination,
    AgGridVue,
    AppInput
  },
  data() {
    return {
      rowStyle: null,
      defaultColDef: null,
      clientsPages: null,
      gridOptions: null,
      filterText: '',
      columnDefs: null,
      rowData: null,
      rowHeight: null,
      frameworkComponents: null,
      getRowStyle: null,
      dataFilterFinish: null,
      dataFilterStart: null,
      inWork: {}
    }
  },
  computed: {
    clientsByPages() {
      return this.$store.state.callCenter.afterFirstVisit.clients
    },
    isModalVisible() {
      return this.$store.state.callCenter.showModal
    }
  },
  watch: {
    clientsByPages({data, ...pages}) {
      console.log(data, pages, 'clientsByPages')
      this.clientsPages = pages
      this.rowData = data
    },
  },
  mounted() {
    this.rowData = this.clientsByPages

    echo.join(`one_record`)
      .here((e) => {
        console.log('here:', e);
      })
      .leaving((e) => {
        delete this.inWork[e.user_id];
        this.gridApi.onFilterChanged();
      })
      .listen('.one_record.in_work', (e) => {
        this.inWork[e.data.user_id] = e.data;
        this.gridApi.onFilterChanged();
      })
      .listen('.one_record.in_work_close', (e) => {
        delete this.inWork[e.data.user_id];
        this.gridApi.onFilterChanged();
      });
  },
  beforeMount() {
    this.gridOptions = {
      context: {
        componentParent: this
      }
    };
    this.columnDefs = [
      {
        headerName: 'Имя',
        field: 'firstname',
        cellClass: ['active-border', 'not-editebl-cell', 'firstname-cell'],
        pinned: 'left',
        lockPosition: true,
        cellRenderer: 'toCallModalCellRenderer'
      },
      {
        headerName: 'Телефон',
        field: 'phone',
        cellClass: ['active-border', 'not-editebl-cell'],
        maxWidth: 140,
        lockPosition: true,
      },
      {
        headerName: 'Дата визита',
        field: 'date',
        maxWidth: 178,
        cellClass: ['not-editebl-cell'],
        filter: 'agDateColumnFilter',
        lockPosition: true,
        filterParams: filterParams
      },
      {
        headerName: 'Время звонка',
        field: 'time_ring',
        lockPosition: true,
        cellClass: ['not-editebl-cell']
      },
      {
        headerName: 'Комментарий',
        editable: true,
        field: 'comment',
        lockPosition: true,
        minWidth: 345,
        cellEditor: 'agLargeTextCellEditor',
        cellClass: ['editebl-cell', 'cell-comment'],
        cellEditorParams: {rows: 1}
      },
      {
        headerName: 'Возраст',
        field: 'age',
        editable: true,
        lockPosition: true,
        maxWidth: 75,
      },
      {
        headerName: '',
        maxWidth: 75,
        lockPosition: true,
        cellClass: ['not-editebl-cell', 'age-calc-cell'],
        valueGetter: this.calcAgeGetter
      },
      {
        headerName: 'Чем занимаетесь',
        field: 'activity',
        maxWidth: 180,
        editable: true,
        lockPosition: true,
        cellClass: ['editebl-cell'],
      },
      {
        headerName: 'Как часто стрижетесь',
        field: 'period_haircut',
        minWidth: 211,
        editable: true,
        lockPosition: true,
        cellClass: ['editebl-cell'],
      },
      {
        headerName: 'Приложение',
        field: 'software',
        maxWidth: 149,
        lockPosition: true,
        editable: true,
        cellClass: ['select-cell'],
        cellEditor: 'applicationEditor',
        singleClickEdit: true
      },
      {
        headerName: 'Оценка от 1 до 10',
        field: 'rating',
        width: 189,
        editable: true,
        lockPosition: true,
        cellClass: ['editebl-cell'],
      },
      {
        headerName: 'Мастер',
        field: 'master',
        lockPosition: true,
        cellClass: ['not-editebl-cell']
      },
      {
        headerName: 'Точка визита',
        field: 'department',
        lockPosition: true,
        cellClass: ['not-editebl-cell', 'department-cell']
      },

    ]
    this.rowData = []
    this.frameworkComponents = {
      applicationEditor: ApplicationEditor,
      toCallModalCellRenderer: CallCenterToCallIcon,
      toCallModalCellEditor: CallCenterToCallModal
    }
    this.rowStyle = {borderBottom: '0.5px solid #F5F5F5'};
    this.defaultColDef = {
      wrapText: true,
      autoHeight: true,
    }
  },
  created() {
    this.$store.dispatch('getUsersOneRecordByPages')
  },
  methods: {
    showModal() {
      this.$store.commit('CHANGE_SHOW_MODAL', true);
    },
    closeModal() {
      apiClient.post('/close-in-work-one-record')
      this.$store.commit("CHANGE_SHOW_MODAL", false);
    },
    getUsersOneRecordByPages(currentPage) {
      if (currentPage) {
        this.$store.dispatch('getUsersOneRecordByPages', currentPage)
      }
    },
    getDataFilterStart(dataStart) {
      this.dataFilterStart = dataStart.target.value
      if (this.dataFilterStart) {
        this.setDataFilter()
        this.sendDate()
      }
    },
    getDataFilterFinish(dataFinish) {
      this.dataFilterFinish = dataFinish.target.value
      if (this.dataFilterFinish) {
        this.setDataFilter()
        this.sendDate()
      }
    },
    sendDate() {
      this.$store.dispatch('getUsersOneRecordByDate', this.checkDateValid())
    },
    checkDateValid() {
      if (!this.dataFilterStart) {
        return {
          date_begin: this.formatDate() + ' 00:00:00',
          date_end: this.dataFilterFinish + ' 00:00:00'
        }
      } else if (!this.dataFilterFinish) {
        return {
          date_begin: this.dataFilterStart + ' 00:00:00',
          date_end: this.formatDate() + ' 00:00:00'
        }
      } else {
        return {
          date_begin: this.dataFilterStart + ' 00:00:00',
          date_end: this.dataFilterFinish + ' 00:00:00'
        }
      }
    },
    formatDate() {
      let d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      return [year, month, day].join('-');
    },
    setDataFilter() {
      var dateFilterComponent = this.gridApi.getFilterInstance('date');
      dateFilterComponent.setModel({
        type: 'inRange',
        dateFrom: `${this.dataFilterStart}`,
        dateTo: `${this.dataFilterFinish}`,
      });
      this.gridApi.onFilterChanged();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      setTimeout(() => {
        this.gridApi.resetRowHeights();
      }, 500);
      this.gridOptions.onCellEditingStarted = (event) => {
        let textarea = document.getElementsByClassName('ag-text-area-input')[0]
        if (textarea) {
          textarea.removeAttribute('rows')
          textarea.setAttribute('style', `height:${event.node.rowHeight}px`)
        }
      };
      let addEffect = false
      this.gridOptions.onBodyScroll = (event) => {
        if (event.left > 0 && !addEffect) {
          addEffect = true
          this.setEffectOnScroll(
            '.ag-pinned-left-cols-container',
            'boxShadow',
            '0px 5px 15px rgba(0, 0, 0, 0.05)')
        } else if (event.left === 0) {
          this.setEffectOnScroll(
            '.ag-pinned-left-cols-container',
            'boxShadow',
            'none')
          addEffect = false
        }
      }
    },
    calcAgeGetter(params) {
      if (+params.data.age) {
        return new Date().getFullYear() - +params.data.age + " г.р"
      } else {
        return ''
      }
    },
    onCellValueChanged() {
      this.$store.commit('UPDATED_COMMENT', this.rowData)
      this.resize()
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.filterText)
    },
    setEffectOnScroll(selector, effect, value) {
      document.querySelector(selector).style[effect] = value
    },
    resize() {
      setTimeout(() => {
        this.gridApi.resetRowHeights();
      }, 100);
    },
    onColumnResized(params) {
      params.api.resetRowHeights();
    },
    onCellEditingStopped() {
      this.onColumnResized();
    },
    isExternalFilterPresent() {
      return true;
    },
    doesExternalFilterPass(node) {
      return !Object.entries(this.inWork).find(item => item[1].record_id === node.data.record_id);
    }
  }
}
var filterParams = {
  filterOptions: [
    'inRange',
  ],
  suppressAndOrCondition: true,
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split('.');
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};

</script>

<style lang="scss">
.add-user-link {
  display: inline-block;
}
</style>
